
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        














.commune-burger__hamburger {
  span {
    display: block;
    width: 20px;
    height: 2px;
    margin-bottom: 3px;
    background-color: $c-white;
    transition: transform 0.3s, opacity 0.4s;
  }

  .is-open & {
    span:nth-child(1) {
      /* stylelint-disable-next-line declaration-no-important */
      transform: translateY(5px) rotate(45deg) !important;
    }

    span:nth-child(2) {
      transform: rotate(-45deg);
    }
  }
}

.my-commune-menu {
  height: 100%;
  width: 80px;

  @include mq($from: $header-commune-breakpoint) {
    width: 138px;
  }

  &__panel-user {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.3rem;
    background-color: $c-orange;
    cursor: pointer;
    height: 100%;

    @include mq($header-commune-breakpoint) {
      justify-content: unset;
      padding: 0 1rem 0 2.4rem;
    }

    > svg {
      fill: $c-white;
    }

    &__icon {
      margin-right: 1rem;
    }
  }
}
