
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        






































.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.container {
  position: relative;
  display: block;
}

.current {
  display: flex;
  align-items: center;
  color: $c-default;
  font-size: 1.3rem;
  text-decoration: none;

  &__icon {
    width: 1rem;
    margin-left: 0.5rem;
    transition: transform 0.25s;

    .container.is-open & {
      transform: scale(-1);
    }
  }
}

.languages {
  position: absolute;
  z-index: 1;
  top: 110%;
  left: -0.5rem;
  padding: 0;
  line-height: 1;
  background: $c-white;
  box-shadow: 0 0 5px rgba($c-black, 0.2);
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.25s;

  .container.is-open & {
    opacity: 1;
    visibility: visible;
  }
}

.languages__item {
  font-size: 1.2rem;
}

.languages__item__link {
  display: block;
  padding: 1rem;
  color: $c-default;
  text-decoration: none;
  transition: background-color 0.25s ease-in-out;
  cursor: pointer;

  &:hover {
    background-color: $c-gray-lighter;
  }

  &.router-link-exact-active {
    color: $c-gray-medium;
    background-color: $c-transparent;
    cursor: default;
  }
}
