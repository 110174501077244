
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        















































































.contact {
  //background-color: $c-footer-bg;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;
  padding: 3rem col(2, 24) 4rem;
  @include mq(s) {
    flex-direction: row;
  }
  @include mq(m) {
    padding: 4rem col(1, 9) 3rem;
  }

  @include mq(wrapper) {
    padding: 4rem col(1, 7) 3rem;
  }
}

.contact__title {
  align-items: center;
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 0;
  font-family: #{$ff-alt};
  font-size: 2.4rem;
  line-height: 1.3em;
  text-transform: uppercase;

  svg {
    display: block;
    margin-right: 1.5rem;
    //margin-bottom: 1.5rem;
    fill: $c-white;
  }

  @include mq(m) {
    //flex-direction: column;
    font-size: 2.4rem;
  }
}

.contact__item {
  flex: 0 0 50%;
  max-width: calc((100% / 2) - 2rem);
  margin-bottom: 3rem;
  font-size: 1.4rem;

  &.horaire {
    margin-bottom: 3rem;
    max-width: calc((100% / 2));

    a.tel {
      text-decoration: none;
      font-weight: 400;
    }

    a.pointer::before {
      background-image: url('../../../assets/svg/icons/pointer.svg');
      background-repeat: no-repeat;
      background-position: left;
      content: '';
      display: inline-block;
      color: $c-white;
      height: 24px;
      vertical-align: middle;
      width: 30px;
    }

    @include mq(s) {
      max-width: calc((100% / 2));
    }
  }

  &.plainte {
    flex-basis: 100%;
    max-width: calc(100%);

    a::before {
      background-image: url('../../../assets/svg/arrows/large/right-white.svg');
      background-repeat: no-repeat;
      background-position: left;
      background-size: 22px auto;
      content: '';
      display: inline-block;
      fill: $c-white;
      height: 4px;
      vertical-align: middle;
      width: 30px;
    }
  }
}

.contact__label {
  color: $c-white;
  font-weight: bold;
  text-decoration: none;
}

.contact__content {
  color: $c-white;

  a {
    display: inline-block;
    margin-top: 2rem;
    color: $c-white;
    font-size: 1.4rem;
    font-weight: 700;
    line-height: 1.3em;
    text-decoration: underline;
    /*&::after {
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      background-color: $c-white;
      transition: background-color $ease-in-out-quad 0.2s;
    }*/
    /* &:hover {
      &::after {
        background-color: $c-white;
      }
    }*/
  }
}
