$icon: '~../assets/images/icons/green-check.svg';

.check-list,
[class*='check-list--'] {
  margin: 0;
  padding: 0;
}

.check-list__item {
  padding: 0 0 0 2.5rem;
  background: transparent url($icon) no-repeat left center;
}
