.vgt-wrap {
  .vgt-inner-wrap {
    border-radius: unset !important;
    box-shadow: unset !important;
  }

  .vgt-responsive {
    border-radius: 1rem;
    box-shadow: 0 8px 16px 0 rgba($c-black, 0.1);
    border: 1px solid $c-gray-lighter;
  }

  table.vgt-table {
    border: unset !important;
    font-size: 1.4rem !important;
    color: $c-gray-darker;
    //table-layout: fixed !important;

    tbody {
      border: unset !important;

      td {
        min-height: 6rem;
        vertical-align: middle;
        border-bottom: unset;
        border-top: 1px solid $c-gray-lighter;
        padding: 0.6rem 2rem;

        .vgt-actions {
          width: 10rem;
          display: block;

          .vgt-btn {
            cursor: pointer;
            transition-property: background-color, fill;
            transition-duration: 0.3s;
            transition-timing-function: ease-in-out;
            margin-bottom: -0.5rem;
            border-radius: 50px;

            &:hover {
              fill: $c-white;
              background: $c-main-primary;
              border-radius: 50px;
            }

          }
        }
      }

      /*tr:last-child{
        td {
          border-bottom: unset;
        }
      }*/
    }

    thead {
      th {
        background: unset !important;
        padding: 2rem !important;
        border-bottom: 1px solid $c-main-primary !important;

        &:last-child {
          width: 14rem;
        }

        &.sorting {
          .custom-header__title {
            color: $c-main-primary;
          }

          .custom-header__icon {
            > svg {
              fill: $c-main-primary;
            }
          }

        }

        &.sortable button {
          cursor: pointer;
        }
      }
    }

    .adresse {
      min-width: 300px;
    }
  }
}

@media screen and (max-width: 767px) {
  .vgt-responsive {
    border-color: transparent;
  }
  .vgt-table {
    .reference, thead, .demande {
      display: none;
    }
  }

  .vgt-wrap table.vgt-table tbody td {
    font-size: 13px;
    padding: 5px 10px;
  }

  .adresse {
    min-width: 0;
  }
}
