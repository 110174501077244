
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        





































































































































































































































































.cat-name {
  color: $c-orange
}

h3 {
  color: $c-orange
}

h3.first-level {
  color: #575756;
}

ul.level-list {
  list-style: none;
  padding: 20px;
  margin: 0;
}

.row {
  @media only screen and (max-width: 767px) {
    &.category-selected {
      display: flex;
      grid-template-columns: unset;
      grid-gap: unset;
    }
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 0 20px;
  }

  ::v-deep .cat {
    @media only screen and (max-width: 767px) {
      min-width: 0;
      min-height: 0;
      overflow: hidden;
    }
  }
}
