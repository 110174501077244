
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        

































.legal {
  background-color: $c-legal-bg;
}

.legal-inner {
  display: flex;
  flex-direction: column;
  max-width: 168rem;
  // padding: 3rem;
  color: $c-white;
  font-size: 1.5rem;

  a {
    color: $c-main-primary;
    text-decoration: none;
  }

  @include mq(m) {
    flex-direction: row;
    justify-content: space-between;
    margin: 0 auto;
    padding: 0;
  }
}

.legal__items {
  // prettier-ignore
  @include fluid(padding-top, (xxs: 40px, m: 20px));
  // prettier-ignore
  @include fluid(padding-bottom, (xxs: 40px, m: 20px));

  display: flex;
  flex-direction: column;
  width: 100%;
  padding-right: col(2, 24);
  padding-left: col(2, 24);

  @include mq(m) {
    flex-direction: row;
    border-bottom: 0;
  }
}

.legal__item {
  display: flex;
  align-items: center;
  color: $c-main-primary;
  font-size: 1.4rem;
  line-height: 1.2;

  a {
    color: $c-main-primary;

    &:hover {
      color: $c-gray-darker;
    }
  }

  @include mq(m) {
    margin-right: 2rem;
  }
}

.legal__social {
  // prettier-ignore
  @include fluid(padding-top, (xxs: 0, m: 20px));
  // prettier-ignore
  @include fluid(padding-bottom, (xxs: 40px, m: 20px));

  width: 100%;

  @include mq(m) {
    width: col(9, 24);
  }

  @include mq(wrapper) {
    width: col(7, 24);
  }
}

.legal__social__items {
  display: flex;
  padding: 0 col(2, 24);

  @include mq(m) {
    padding: 0 col(2, 24);
  }

  @include mq(l) {
    padding: 0 col(1, 9);
  }

  @include mq(wrapper) {
    padding: 0 col(1, 7);
  }
}

.legal__social__item {
  margin-right: 2rem;

  a {
    align-items: center;
    background: #ffffff;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
    border-radius: $border-radius;
    color: $c-main-primary;
    display: flex;
    height: 35px;
    justify-content: center;
    width: 35px;

    svg {
      fill: $c-main-primary;
    }

    &:hover {
      svg {
        fill: $c-main-secondary;
      }
    }
  }

  svg {
    fill: $c-gray-medium;
  }
}
