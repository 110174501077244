
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        

































.video,
[class*='video--'] {
  position: relative;
  padding-top: (9 / 16) * 100%;

  img {
    filter: none;
  }

  &[class*='--btn'] {
    display: inline-block;
    overflow: hidden;
    padding-top: 0;
    border-radius: 30px;
  }

  ::v-deep .close {
    display: none;
  }

  &:hover {
    cursor: pointer;
  }
}

.video__poster {
  @include image-fit;
}

.video__content {
  @include get-all-space;

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;

  &::before {
    @include get-all-space;

    content: '';
    background-image: linear-gradient(to top, $c-orange, $c-transparent);
  }

  [class*='video--'][class*='--btn'] & {
    position: static;
    padding: 0.5rem;
    background: $c-orange;

    &::before {
      display: none;
    }
  }

  @include mq(l) {
    align-items: flex-end;
  }
}

.video__content__title {
  @extend %fw-medium;
  // prettier-ignore
  @include fluid(font-size, (xxs: 15px, m: 18px));

  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $c-white;
  line-height: 1.2;

  span {
    display: none;
  }

  .icon {
    fill: $c-white;
    transition: transform 0.2s;

    [class*='video--']:hover & {
      transform: scale(1.05);
    }
  }

  [class*='video--'][class*='--poster'] & {
    align-self: center;
  }

  [class*='video--'][class*='--btn'] & {
    // prettier-ignore
    @include fluid(margin-left, (xxs: 20px, m: 25px));

    flex-direction: row-reverse;

    span {
      display: block;
    }

    .icon {
      // prettier-ignore
      @include fluid(margin-left, (xxs: 25px, m: 20px));
      // prettier-ignore
      @include fluid(width, (xxs: 30px, m: 55px));
      // prettier-ignore
      @include fluid(height, (xxs: 30px, m: 55px));

      margin-right: 0;
    }
  }

  @include mq(l) {
    span {
      display: block;
    }

    .icon {
      margin-right: 1rem;
    }
  }
}

.video__player {
  position: relative;
  width: 100vw;
  max-width: 120rem;
  padding-top: (9 / 16) * 100%;

  iframe {
    @include image-fit;
  }
}
