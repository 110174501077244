
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        









































































.cta-rich,
[class*='cta-rich--'] {
  // prettier-ignore
  @include fluid(padding-top, (xxs: 20px, m: 30px));
  // prettier-ignore
  @include fluid(padding-right, (xxs: 15px, m: 25px));
  // prettier-ignore
  @include fluid(padding-bottom, (xxs: 20px, m: 40px));
  // prettier-ignore
  @include fluid(padding-left, (xxs: 20px, m: 40px));
  border-radius: $border-radius;
  box-shadow: $box-shadow;
  position: relative;
  text-align: left;

  &.orange,
  &.yellow {
    ::v-deep [class*='content__btn'] {
      &:hover {
        background: transparent !important; // stylelint-disable-line
      }
    }

    ::v-deep [class*='content__link'] {
      // stylelint-disable-next-line
      .action__label {
        color: $c-white;
        border-color: $c-white;
      }

      // stylelint-disable-next-line
      .action__icon {
        fill: $c-white;
      }
    }
  }

  &.yellow {
    background-color: $c-main-secondary;
  }

  &.orange {
    background-color: $c-orange;
  }

  &.black {
    background-color: $c-black;
  }

  &.white {
    background-color: $c-white;
  }

  &.gray {
    background-color: $c-gray-darker;
  }

  &.green {
    background-color: $c-green;
  }

  &[class*='--large'] {
    // prettier-ignore
    @include fluid(padding-top, (xxs: 65px, m: 120px));
    // prettier-ignore
    @include fluid(padding-right, (xxs: 55px, m: 140px));
    // prettier-ignore
    @include fluid(padding-bottom, (xxs: 65px, m: 120px));
    // prettier-ignore
    @include fluid(padding-left, (xxs: 55px, m: 140px));
  }

  .row-title {
    align-items: center;
    margin-bottom: 20px;
    .content__icon + .col {
      @include fluid(
        padding-top,
        (
          xxs: 15px,
          m: 40px,
        )
      );
    }
    .content__title {
      width: calc(100% - 38px);
    }
  }
  // press news contact
  .contact & {
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
    .row-double {
      width: 100%;
    }
    .footer__icon {
      display: none;
    }
  }
}

.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
}

.content__icon {
  margin-bottom: 1rem;
  margin-left: -5px;
  min-width: 4rem;

  &.white {
    fill: $c-white;
  }

  &.orange {
    fill: $c-orange;
  }

  &.yellow {
    fill: $c-main-secondary;
  }

  &.green {
    fill: $c-green;
  }
}
.footer__icon {
  width: 26px;
  height: 26px;
  padding: 6px;
  background: $c-orange;
  border-radius: 50%;
  fill: $c-white;
  margin-right: 1.2rem;
  margin-top: 0;
  transform: rotate(-90deg);
  transition: transform 0.2s;
  .cta:hover & {
    transform: translateX(1rem) rotate(-90deg);
  }
}
.content__headline {
  @include fluid(
    font-size,
    (
      xxs: 14px,
      m: 18px,
    )
  );
  // prettier-ignore
  @include fluid(margin-bottom, (xxs: 10px, m: 20px));
}

.content__title {
  @extend %fw-light;
  // prettier-ignore
  @include fluid(font-size, (xxs: 24px, m: 30px));
  color: $c-main-primary;
  font-family: $ff-default;
  font-weight: 700;
  line-height: 1;
  [class*='cta-rich--'][class*='--large'] & {
    // prettier-ignore
    @include fluid(font-size, (xxs: 24px, m: 46px));
  }
}

.yellow .content__title,
.orange .content__title {
  color: white;
}

.content__text {
  color: $c-gray-darker;
}

.content__btn {
  margin-top: 25px;
  margin-bottom: 0;

  .dark & {
    background: transparent;
    border: 1px solid $c-white;
  }
}

@media (min-width: 961px) {
  .content__btn {
    align-self: flex-start;
  }
}

.content__link {
  margin-top: auto;
}

.content__btn + .content__link {
  margin-top: 4rem;
}
