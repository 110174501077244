
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        

















.g-link,
[class*='g-link--'] {
  display: flex;
}

.g-link__label {
  [class*='g-link--'][class*='--underline'] & {
    border-bottom: 1px solid $c-red;
  }
}

.g-link__arrow {
  margin-left: 0.75em;
  opacity: 0;
  transform: translateX(-50%);
  transition: opacity 0.2s ease-in-out, transform 0.35s ease-in-out;

  :hover > & {
    opacity: 1;
    transform: translateX(0);
  }
}
