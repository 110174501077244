
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        
































































































































.header {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: $header-height;
  background-color: $c-white;
  border-bottom: 1px solid $c-gray-light;

  @include mq($header-breakpoint) {
    height: $header-height-big;
  }
}

.header-inner {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.header__nav {
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  margin-right: 7rem;

  @include mq($header-breakpoint) {
    margin-right: 18rem;
  }
}

.header__nav__logo {
  position: relative;
  z-index: 2;
  display: block;
  //margin-left: 2rem;
  padding-top: 7px;
  padding-left: 8.3333333333%;

  svg {
    // prettier-ignore
    @include fluid(width, (xxxs: 60px, xl: 100px));
    height: auto;
    fill: $c-main-primary;
  }

  @include mq(l) {
    margin-left: 4rem;
    padding-left: 0;
  }
}

.header__lang {
  margin-right: 1rem;
}

.header__nav__myresa {
  ::v-deep {
    padding: 0.75rem 8px 0.75rem 8px;

    .action__label {
      background-image: url('../../assets/svg/logos/logo_my_resa.svg');
      background-size: 67px auto;
      background-position: center;
      background-repeat: no-repeat;
      display: none;
      height: 15px;
      margin-right: 5px;
      margin-top: 6px;
      overflow: hidden;
      text-indent: -400px;
      width: 70px;
      @include mq(m) {
        display: inline-block;
        margin-left: 3px;
      }
    }

    .action__icon-wrapper {
      margin-right: 0;
    }

    @include mq(m) {
      //padding: 0.75rem 10px 0.75rem 8px;
    }
  }

  .btn__label {
    /* stylelint-disable-next-line */
    color: $c-black !important;
    font-weight: 700;
  }

  .btn__icon {
    /* stylelint-disable-next-line */
    fill: $c-black !important;
  }
}

.header__mega {
  z-index: 1;
}
