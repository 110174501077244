
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        










































.intro {
  @include fluid(
    padding-right,
    (
      xs: 0px,
      s: 0px,
    )
  );
}

.intro__content__title {
  @extend %fw-light;
  // prettier-ignore
  @include fluid(font-size, (xxs: 36px, xl: 48px));
  // prettier-ignore
  @include fluid(margin-bottom, (xxs: 15px, xl: 40px));
  // prettier-ignore
  @include fluid(font-size, (xxs: 18px, xl: 24px));
}

.intro__content__btn {
  // prettier-ignore
  @include fluid(margin-top, (xs: 15px, s: 25px));
}

.intro__content__animations {
  margin-top: 3rem;

  @include mq($until: s) {
    display: none;
  }
}

.intro__content__animations__gaz {
  width: 80%;
  max-width: 28rem;
  margin-left: auto;
}

.items {
  @include fluid(
    row-gap,
    (
      m: 40px,
      l: 75px,
    )
  );
  @include mq($until: s) {
    display: flex;
    flex-direction: column;
    row-gap: 40px;
    padding-top: 40px;
  }
  @include mq(s) {
    border-left: 1px solid $c-gray-lighter;
    /*display: flex;
    flex-direction: column;
   row-gap: 40px;*/
  }
  @include mq(l) {
    /*border-left: 1px solid $c-gray-lighter;
    display: flex;
    row-gap: 40px;*/
  }
  @include mq(xl) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

.items__step {
  @include mq(xl) {
    //width: col(6, 12);
    width: 49%;
  }
}
