
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        









.gallery {
  position: relative;
  width: 100%;
  height: 100% !important; // stylelint-disable-line
  background-color: $c-gray-light;

  &::before {
    content: '';
    display: block;
    width: 100%;
    padding-top: (9 / 16) * 100%;
  }
}

.container {
  ::v-deep {
    .gallery-outer {
      position: relative;
      margin-bottom: 7rem;
    }

    .gallery-controls {
      position: absolute;
      z-index: 5;
      right: 2rem;
      bottom: 0;
      display: flex;
      height: 6rem;
      background-color: $c-white;
    }

    .gallery-controls__item,
    [class*='gallery-controls__item--'] {
      display: flex;
      flex: 1;
      padding-right: 1.5rem;
      background-color: $c-white;
      border: none;
      cursor: pointer;

      // stylelint-disable-next-line
      svg {
        width: 2.4rem;
        height: 2.4rem;
        margin: auto;
      }

      &:hover {
        // stylelint-disable-next-line
        svg {
          fill: $c-main-primary;
        }
      }
    }

    .gallery-controls__item--prev {
      transform: rotate(180deg);
    }

    .gallery-pagination {
      position: absolute;
      z-index: 5;
      right: 0;
      bottom: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 3rem;
      height: 6rem;
      font-size: 1.3rem;
      background-color: $c-white;
    }

    .gallery-pagination__current {
      color: $c-main-primary;
    }

    .gallery-pagination__separator {
      display: block;
      width: 1rem;
      height: 0.1rem;
      color: transparent;
      background-color: $c-gray-light;
    }
  }
}

.gallery__item {
  @include get-all-space;

  opacity: 0;

  &::before {
    display: none;
  }

  &:first-child {
    z-index: 2;
    opacity: 1;
  }

  img {
    @include image-fit;
  }
}
