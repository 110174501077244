
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        

































.modal-overlay {
  &.--alt-commune {
    justify-content: center;

    .modal {
      margin-right: unset;
    }
  }
}

.modal-overlay {
  z-index: 99;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0px 10px;
  display: flex;
  justify-content: right;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
}

.modal {
  position: relative;
  display: table;
  /* flex-direction: column; */
  overflow: auto;
  background-color: white;
  // padding: 60px 0;
  //border-radius: 20px;
  max-height: 52%;
  box-shadow: 2px 2px 60px 1px;
  /* overflow: hidden; */
  width: auto;
  margin-right: 10%;
  // margin-top: 10%;
}

.modal-body,
.modal-footer {
  position: relative;
  padding: 20px 10px;
  margin: 20px;
}

::v-deep {
  .modal-body p {
    margin-top: 25px;
  }
}

.modal-footer {
  bottom: 0;
}

.close {
  border: 1px solid #e84e0f;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  fill: #e84e0f;
  height: 5.4rem;
  top: 30px;
  right: 20px;
  width: 5.4rem;
  position: absolute;
  z-index: 1;
  cursor: pointer;
}

@media (max-width: 990px) {
  .modal {
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: auto;
    background-color: white;
    padding: 60px 0;
    // max-width: 70%;
    max-height: 65%;
    box-shadow: 2px 2px 60px 1px;
    margin: 0;
  }

  .modal-overlay {
    justify-content: center;
  }
}
