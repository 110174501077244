
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        





























































































// Defaults
a.action,
[class*='action--'] {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: $c-main-primary;
  line-height: 1;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
  }

  .cta-home & {
    @include mq($until: s) {
      display: flex;
      flex: 1;
      width: 100%;
    }
  }
}

// Links
[class*='action--'][class*='--rounded'] {
  @extend %fw-medium;
  @extend %border-radius;

  // prettier-ignore
  @include fluid(padding-top, (xxs: 20px, m: 30px));
  // prettier-ignore
  @include fluid(padding-right, (xxs: 20px, m: 40px));
  // prettier-ignore
  @include fluid(padding-bottom, (xxs: 20px, m: 30px));
  // prettier-ignore
  @include fluid(padding-left, (xxs: 20px, m: 40px));

  justify-content: space-between;
  background: $c-white;
  border: 1px solid $c-gray-light;
  transition: all 0.3s;

  &:hover {
    border: 1px solid $c-white;
    box-shadow: 0 10px 30px rgba($c-black, 0.1);
    transform: translateY(-2px);
  }
}

[class*='action--'][class*='--link'] {
  color: $c-default;
  text-decoration: none;

  &[class*='--negative'] {
    color: $c-white;
  }

  &[class*='--dark'] {
    color: $c-black;
  }

  &[class*='--reverse'] {
    display: flex;
    flex-direction: row-reverse;
  }
}

// Buttons (tag OR .btn)
// button.action,
// button[class*='action--'],
a.action.btn,
[class*='action--'][class*='--btn'] {
  padding: 1rem 2rem;
  color: $c-white;
  font-size: 1.3rem;
  text-decoration: none;
  background-color: $c-main-primary;
  border: 0;
  border-radius: 99em;
  transition: background-color 0.15s, color 0.15s, border 0.15s;

  &:disabled {
    opacity: 0.5;
  }

  /* stylelint-disable-next-line selector-max-pseudo-class */
  &:hover:not(:disabled):not([class*='--outline']):not([class*='--negative']) {
    cursor: pointer;
    background-color: darken($c-main-primary, 3);
  }

  &[class*='--full'] {
    width: 100%;
  }

  &[class*='--uppercase'] {
    text-transform: uppercase;
  }

  &[class*='--rectrounded'] {
    border-radius: 1em;

    &[class*='--small'] {
      border-radius: 0.5em;
    }
  }

  &[class*='--whiteborder'] {
    border: 1px solid $c-white;
  }

  &[class*='--yellow'] {
    background-color: $c-main-secondary;

    &:hover:not(:disabled):not([class*='--outline']):not(
        [class*='--negative']
      ) {
      background-color: darken($c-main-secondary, 3);
    }
  }

  &[class*='--orange'] {
    background-color: $c-main-primary;

    &:hover:not(:disabled):not([class*='--outline']):not(
        [class*='--negative']
      ) {
      background-color: darken($c-main-primary, 3);
    }
  }

  &[class*='--green'] {
    background-color: $c-green;

    &:hover:not(:disabled):not([class*='--outline']):not(
        [class*='--negative']
      ) {
      background-color: darken($c-green, 3);
    }

    &[class*='--white'] {
      border: 1px solid $c-green;

      &:hover:not(:disabled):not([class*='--outline']):not(
          [class*='--negative']
        ) {
        background-color: $c-white;
        border: 1px solid $c-green;
        color: $c-green;

        > svg {
          fill: $c-green;
        }
      }
    }
  }

  &[class*='--right'] {
    display: flex;
    margin-left: auto;
  }

  &[class*='--big'] {
    padding: 1.8rem 2.8rem;
    font-size: 1.5rem;
    border-radius: 35px;

    @include mq('m') {
      font-size: 2rem;
    }

    &[class*='--rectrounded'] {
      border-radius: 0.2em;
    }
  }

  &[class*='--collapsed'] {
    padding-right: 1rem;
  }

  &[class*='--negative'],
  &[class*='--outline'] {
    border-width: 1px;
    border-style: solid;
  }

  &[class*='--negative'] {
    background-color: transparent;
    color: $c-main-primary;

    &[class*='--yellow'] {
      color: $c-main-secondary;
    }

    &[class*='--green'] {
      color: $c-green;
    }

    &[class*='--orange'] {
      color: $c-main-primary;
    }
  }

  &[class*='--outline'] {
    color: $c-main-primary;
    background-color: $c-white;
    border-color: $c-gray-light;

    &:hover:not(:disabled) {
      background-color: $c-white;
      border-color: $c-main-primary;
    }

    &.header__nav__myresa {
      border-color: $c-main-primary;
      padding: 0.75rem 8px 0.75rem 8px;
    }

    &[class*='--green'] {
      color: $c-green;
      border-color: $c-green;
    }

    &[class*='--green']:hover:not(:disabled) {
      color: $c-white;
      background-color: $c-green;
      border-color: $c-green;
    }
  }

  &[class*='--reverse'] {
    flex-direction: row-reverse;
  }

  @include mq('m') {
    font-size: 1.5rem;
  }
}

/* stylelint-disable-next-line block-no-empty */
.action__label {
  @extend %fw-medium;
  // prettier-ignore
  @include fluid(font-size, (xxs: 13px, m: 15px));

  transition: color 0.15s, border 0.15s;

  [class*='action--'][class*='--fixed'] & {
    font-size: 1.3rem;
  }

  [class*='action--'][class*='--underline'] & {
    padding-bottom: 0.5rem;
    border-bottom: 1px solid $c-main-primary;
  }

  [class*='action--'][class*='--link']:not([class*='--btn']) & {
    color: $c-main-primary;
  }

  &[class*='--negative'] {
    color: $c-main-secondary;
  }

  [class*='action--'][class*='--link'][class*='--negative'] & {
    &[class*='--yellow'] {
      color: $c-main-secondary;
    }

    &[class*='--green'] {
      color: $c-green;
    }

    &[class*='--orange'] {
      color: $c-main-primary;
    }
  }

  [class*='action--'][class*='--link'][class*='--dark'] & {
    color: $c-black;
  }

  [class*='action--'][class*='--link'][class*='--dark']:hover & {
    color: $c-black;
  }

  /* stylelint-disable no-descending-specificity */
  [class*='action--'][class*='--underline'][class*='--negative'] & {
    padding-bottom: 0.5rem;
    border-bottom: 1px solid $c-white;
  }

  [class*='action--'][class*='--underline'][class*='--dark'] & {
    padding-bottom: 0.5rem;
    border-bottom: 1px solid $c-black;
  }

  [class*='action--'][class*='--underline'][class*='--dark']:hover & {
    border-bottom: 1px solid $c-orange;
  }

  /* stylelint-enable no-descending-specificity */
}

.action__icon-wrapper {
  margin-left: 0.75em;
  flex: 0 0 auto;

  [class*='--reverse'] & {
    margin-right: 0.75em;
    margin-left: 0;
  }

  [class*='action--'][class*='--icon'][class*='--arrow'] & {
    overflow: hidden;
  }

  [class*='--no-label'] & {
    margin-left: 0;
  }
}

.action__icon-wrapper-sup {
  overflow: hidden;
  margin-right: 0.75em;
}

.action__icon {
  color: inherit;
  vertical-align: middle;
  transition: transform 0.15s ease-in-out;
  fill: currentColor;

  [class*='--link'] & {
    fill: $c-main-primary; // Different icon color when used within link
  }

  [class*='--dark'] & {
    fill: $c-black;
  }

  [class*='--btn'] & {
    fill: $c-white;
  }

  [class*='--negative'] &,
  [class*='--outline'] & {
    fill: currentColor;
  }

  [class*='--collapsed'] & {
    width: 3.2rem;
    height: 3.2rem;
    margin-top: -0.4rem;
    margin-right: -0.4rem;
    margin-bottom: -0.4rem;
  }

  [class*='--static'] & {
    :hover:not(:disabled) > & {
      transform: none;
    }
  }

  [class*='action--'][class*='--no-animation']:hover & {
    transform: none !important; /* stylelint-disable-line declaration-no-important */
    animation: none !important; /* stylelint-disable-line declaration-no-important */
  }

  [class*='action--']:hover:not(:disabled) & {
    transform: translateX(0.3rem);
  }

  [class*='action--'][class*='--arrow']:hover:not(:disabled) & {
    animation: arrow linear 0.3s;
    transform: none;
  }

  [class*='action--'][class*='--icon'][class*='--download'] &,
  [class*='action--'][class*='--icon'][class*='--icons-profile'] & {
    width: 1.4rem;
    height: 1.4rem;
  }

  [class*='action--'][class*='--icon'][class*='--warning'] & {
    width: 20px;
    height: auto;
  }

  [class*='--collapsed'][class*='--big'] & {
    width: 4.4rem;
    height: 4.4rem;
    margin: -1.2rem -0.4rem -1.2rem 1.2em;

    @include mq('m') {
      width: 5.4rem;
      height: 5.4rem;
    }
  }

  .action__icon-wrapper-sup & {
    :hover:not(:disabled) > & {
      transform: none !important; /* stylelint-disable-line declaration-no-important */
    }
  }

  /* stylelint-enable no-descending-specificity */
}

@keyframes arrow {
  50% {
    transform: translateX(100%);
  }

  50.01% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(0);
  }
}
