
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        





































.accordion-item {
  border-bottom: 1px solid $c-black;
}

.accordion-item__header {
  user-select: none;
  transition: color 0.2s ease-out;
  cursor: pointer;
}

.accordion-item__header-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem 0;
}

.accordion-item__header__title {
  font-weight: 300;
  padding-bottom: 0;
}
.accordion-item__header__icon {
  fill: $c-main-primary;
  flex-shrink: 0;
  overflow: visible;
  width: 1.2rem;
  height: 1.2rem;
  margin-left: 1.5rem;
  transition: all 0.2s ease-in-out;
  .accordion-item.is-open & {
    transform: rotate(-180deg);
  }
}

.accordion-item__text {
  overflow: hidden;
  color: $c-gray-darker;
  line-height: 1.6;
}
