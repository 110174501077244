
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        











.callout {
  padding: 20px;
  margin: 20px 0 20px 35px;
  border-left: 4px solid #3e7a6b;
  background-color: #f5f8f7;
  p {
    color: #3e7a6b;
    margin-top: 0;
    margin-bottom: 5px;
    margin-left: 15px;
  }
  svg {
    fill: #3e7a6b;
    transform: translate(5px, 5px);
    margin-right: 10px;
  }
  div {
    margin-left: 15px;
  }
}
