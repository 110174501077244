
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        































.search-wp {
  min-width: 30px;
  margin-right: 1rem;
}

.header_svg {
  cursor: pointer;
  fill: #e84e0f;
}

.close {
  border: 1px solid $c-main-primary;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  fill: #5dae99;
  height: 5.4rem;
  right: 1rem;
  //top: 7rem;
  // prettier-ignore
  @include fluid(top, (xxs: 30px, xxl: 70px));
  // prettier-ignore
  @include fluid(right, (xxs: 20px, xxl: 40px));
  width: 5.4rem;
  z-index: 1;
}

.modal-backdrop {
  position: fixed;
  padding: 0 10px;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}

.input::placeholder {
  font-style: italic;
}

.modal {
  background: #5dae99;
  position: relative;
  padding: 30px;
  box-shadow: 2px 2px 60px 1px;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  overflow-y: auto;

  ::v-deep .modal-body h1 {
    margin-top: 30px;
  }

  @media only screen and (max-width: 1368px) {
    width: 100%;
    height: 95%;
    padding: 60px 12px 0px;
  }
}

.modal-header,
.modal-footer {
  padding: 15px;
  display: flex;
}

.modal-header {
  position: absolute;
  right: 10px;
  top: 10px;
  color: #4aae9b;
  justify-content: space-between;
}

.modal-header svg {
  fill: #5dae99;
}

.modal-header a {
  background: white;
  border: 0;
}

.modal-footer {
  border-top: 1px solid #eeeeee;
  flex-direction: column;
  justify-content: flex-end;
}

.modal-body {
  position: relative;
  padding: 20px 10px;
}

.modal-body h1 {
  color: white;
}

.modal-body input {
  width: calc(100% - 100px);
  padding: 10px 20px;
  border: 0;
  border-radius: 100px;
  font-size: 14px;
  font-style: italic;
}

.modal-body input + svg {
  fill: #e84e0f;
  transform: translate(-40px, 7px);
}

.modal-body ul a {
  color: white;
  margin-bottom: 1em;
  display: inline-block;
}

.btn-close {
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  font-size: 20px;
  padding: 10px;
  cursor: pointer;
  font-weight: bold;
  color: #4aae9b;
  background: transparent;
}

.btn-green {
  color: white;
  background: #4aae9b;
  border: 1px solid #4aae9b;
  border-radius: 2px;
}

@media screen and (max-width: 64em) {
  .modal-body h1 {
    margin-top: 3em;
  }
}
