
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        




























.cta-banner {
  ::v-deep {
    gap: 25px 25px;
    position: relative;
    @include mq(m) {
      padding: 75px 0;
    }

    .cta-rich.cta {
      @include mq($until: s) {
        margin-bottom: 0;
        padding-bottom: 0;
      }
    }

    .cta-rich .row-title .content__title {
      width: 100%;
    }

    .footer__icon {
      display: none;
    }
  }
}

.picture {
  @include mq(m) {
    background-color: $c-gray-lightest;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% - #{col(5)});
    height: 100%;
    img {
      @include image-fit;
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
      filter: none;

      .home__banner & {
        border-bottom-right-radius: 0;
      }
    }
    @include mq(wrapper) {
      width: calc(
        100vw - ((100vw - #{$content-width}) / 2 + (#{$content-width} / 5))
      );
    }

    .cta-banner--left & {
      right: 0;
      left: unset;
      border-radius: 2rem 0 0 0;
      img {
        border-radius: 2rem 0 0 0;
      }
    }
  }
}

.cta {
  // prettier-ignore
  @include fluid(padding-top, (s: 30px, m: 40px));
  //@include fluid(padding-right, (s: 30px, m: 40px));
  @include fluid(
    padding-bottom,
    (
      s: 30px,
      m: 40px,
    )
  );
  // prettier-ignore
  @include fluid(margin-bottom, (s: 30px, m: 75px));
  background: $c-white;
  //border-top-right-radius: 0px;
  //border-bottom-right-radius: 0px;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
  overflow: hidden;
  transform: translateY(-9rem);
  position: relative;
  z-index: 1;

  @include mq(m) {
    width: col(9, 20);
    margin: 0 0 0 auto;
    padding-right: col(1);
    transform: none;
  }

  @include mq(xl) {
    width: col(9, 20);
    margin: 0 0 0 auto;
    padding-right: col(2);
    //padding-left: col(2, 20);
    //padding: 0 col(2) 0 0;
  }

  @include mq(wrapper) {
    width: col(9, 20);
  }

  &--left {
    margin: unset;
    padding-left: col(1);
  }
}

.cta__gaz {
  position: absolute;
  top: 80%;
  right: 0;
  width: 50%;
  height: 100%;

  @include mq(xs) {
    top: 70%;
    bottom: auto;
    width: 70%;
  }
}
