
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        








































.text {
  @extend %fw-light;
}

hr {
  display: none;
  width: 100%;
  background: $c-gray-light;
}
