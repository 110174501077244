
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        




























.side-panel-outer {
  position: fixed;
  z-index: 99;
  top: 0;
  right: 0;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  background: rgba($c-black, 0.75);
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.35s $ease-in-quad 0.5s;

  &.is-visible {
    opacity: 1;
    pointer-events: all;
    transition: opacity 0.35s $ease-out-quad;
  }
}

.panel {
  position: absolute;
  top: 0;
  right: 0;
  overflow: auto;
  width: 100vw;
  max-width: 70rem;
  height: 100vh;
}

.panel__component {
  background-color: $c-white;
  min-width: 100%;
  min-height: 100vh;
  //padding:12rem 2rem 4rem 2rem;
  // prettier-ignore
  @include fluid(padding-top, (xxs: 80px, xxl: 150px));
  @include fluid(
    padding-right,
    (
      xxs: 20px,
      xxl: 80px,
    )
  );
  @include fluid(
    padding-bottom,
    (
      xxs: 30px,
      xxl: 80px,
    )
  );
  @include fluid(
    padding-left,
    (
      xxs: 20px,
      xxl: 64px,
    )
  );
}

.close {
  border: 1px solid $c-main-primary;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  fill: $c-main-primary;
  height: 5.4rem;
  //right: 1rem;
  //top: 7rem;
  // prettier-ignore
  @include fluid(top, (xxs: 30px, xxl: 70px));
  // prettier-ignore
  @include fluid(right, (xxs: 20px, xxl: 40px));
  width: 5.4rem;
  position: absolute;
  z-index: 1;
}

// Transitions
.panel-enter-active {
  transition: transform 0.5s ease-out;
}

.panel-leave-active {
  transition: transform 0.3s ease-in;
}

.panel-leave,
.panel-enter-to {
  transform: translateX(0);
}

.panel-enter,
.panel-leave-to {
  transform: translateX(100%);
}
