
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        








.tip-icon {
  display: inline-flex;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
  fill: $c-orange;
}
.tip__icon {
  margin-right: 10px;
}
.tip-wording {
  color: $c-orange;
}
