
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        

















.not-found-inner {
  max-width: 90rem;
}

.not-found__suptitle {
  @include fluid(
    font-size,
    (
      xxs: 12px,
      m: 15px,
    )
  );

  color: $c-main-primary;
}

.not-found__subtitle {
  @include fluid(
    font-size,
    (
      xxs: 18px,
      m: 24px,
    )
  );
}
