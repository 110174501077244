// Before wrapper mq = 24 available columns
// After wrapper mq = 22 available columns
.wrapper,
[class*='wrapper--'] {
  width: 100%;
  max-width: $content-width;
  padding-right: col(2, 24);
  padding-left: col(2, 24);

  &[class*='--no-padding'] {
    padding-right: 0;
    padding-left: 0;
  }

  @include mq(m) {
    padding-right: col(1, 24);
    padding-left: col(1, 24);
  }

  @include mq(xl) {
    padding-right: col(2, 24);
    padding-left: col(2, 24);
  }

  @include mq(wrapper) {
    margin-right: auto;
    margin-left: auto;
    padding-right: calc(#{$content-width} / 24 * 2);
    padding-left: calc(#{$content-width} / 24 * 2);
  }
}

// Before wrapper mq = 24 available columns
// After wrapper mq = 22 available columns
.wrapper-left,
[class*='wrapper--'] {
  width: 100%;
  max-width: $content-width;
  padding-left: col(2, 24);
  padding-right: col(2, 24);

  &[class*='--no-padding'] {
    padding-left: 0;
  }

  @include mq(m) {
    padding-left: col(1, 24);
    padding-right: col(1, 24);
  }

  @include mq(l) {
    padding-right: 0;
  }

  @include mq(xl) {
    padding-left: col(2, 24);
    margin-right: 0;
  }

  @include mq(wrapper) {
    // margin-left: auto;
    // margin-right: auto;
    padding-left: calc(#{$content-width} / 24 * 2);
  }
}

.commune {
  .wrapper {
    padding-right: 2rem;
    padding-left: 2rem;

    &[class*='--no-padding'] {
      padding-right: 0;
      padding-left: 0;
    }

    @include mq(m) {
      padding-right: col(2, 24);
      padding-left: col(2, 24);
    }

    @include mq(wrapper) {
      margin-right: auto;
      margin-left: auto;
      padding-right: calc(#{$content-width} / 24 * 2);
      padding-left: calc(#{$content-width} / 24 * 2);
    }
  }
}

