
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        

























































































.my-header-user-outer {
  position: relative;
}

.my-header-user {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 4rem;
  font-size: 1.3rem;
  cursor: pointer;

  @include mq($until: $header-commune-breakpoint) {
    border-radius: 30px;
    border: 1px solid $c-green;
    width: 4rem;
    text-align: center;
  }

  &__icon {
    fill: $c-orange;
    margin-right: 1rem;

    @include mq($until: $header-commune-breakpoint) {
      fill: $c-green;
      margin-right: unset;
    }
  }

  &__arrow {
    width: 1rem;
    transition: transform 0.25s;
    @include mq($until: $header-commune-breakpoint) {
      display: none;
    }

    .is-dropdown & {
      transform: scale(-1);
    }
  }

  &__name {
    margin-right: 0.4rem;

    @include mq($until: $header-commune-breakpoint) {
      display: none;
    }
  }
}

.my-panel {
  &--content {
    border-radius: 8px;
    border: none;
    top: calc(100% + 1.6rem);
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    padding: 4rem 2rem 2rem 2rem;
    min-width: 185px;

    a {
      color: $c-gray-darker;
      margin-bottom: 1rem;
      font-size: 1.3rem;
    }

    button {
      margin-top: 2rem;
      text-transform: uppercase;
    }
  }

  &__title {
    font-weight: bolder;
    margin-bottom: 1rem;
    font-size: 1.3rem;
    text-transform: uppercase;
  }

  &__arrow {
    left: calc(50% - 5rem);
    width: 10rem;
    height: 2.5rem;
    top: -2.5rem;

    &:before {
      border-radius: 8px 0 0 0;
      width: 2.5rem;
      height: 2.5rem;
      border: unset;
      left: calc(50% - 1.25rem);
    }
  }
}

.other-links-container {
  border-top: 1px solid $c-green;

  .nav-items {
    margin-top: 1em;
  }
}
