
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        









.block {
  padding: 40px;
  border: 1px solid #a4a4a4;
  border-radius: 20px;
  line-height: 25px;
}

.block__headline {
  font-size: 2rem;
  font-family: $ff-alt;
}

.block__title {
  color: #a4a4a4;
  font-family: $ff-default;
  margin-bottom: 20px;
  font-size: 1.8rem;
  line-height: 20px;
}

.block__text {
  font-family: $ff-default;
  font-size: 1.8rem;
  line-height: 20px;

  ul {
    padding: 0px;
  }
}
