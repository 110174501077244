
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        































.wizard__header {
  display: flex;
  justify-content: space-between;
  margin-right: col(2, 22);
}

.wizard__list {
  margin: 0;
  padding: 0;
  transition: opacity 0.3s;
}

.wizard__list__item {
  @extend %fw-medium;
  @extend %border-radius;

  list-style-type: none;
  background: $c-white;
  border: 1px solid $c-gray-light;
  transition: all 0.3s;

  ::v-deep {
    .action__label {
      color: $c-black !important; // stylelint-disable-line declaration-no-important
      font-size: $body-text;
    }

    .action__icon {
      fill: $c-main-primary; // stylelint-disable-line declaration-no-important
    }
  }

  &::before {
    display: none;
  }

  &:hover {
    border: 1px solid $c-white;
    box-shadow: 0 10px 30px rgba($c-black, 0.1);
    transform: translateY(-2px);
  }

  ::v-deep [class*='action--link'] {
    // prettier-ignore
    @include fluid(padding-top, (xxs: 15px, m: 17px));
    // prettier-ignore
    @include fluid(padding-right, (xxs: 15px, m: 20px));
    // prettier-ignore
    @include fluid(padding-bottom, (xxs: 15px, m: 17px));
    // prettier-ignore
    @include fluid(padding-left, (xxs: 15px, m: 20px));

    display: flex;
    justify-content: space-between;
  }
}
