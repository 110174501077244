
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        

























































































































































































































































































































.menu-title {
  font-size: 1em;
  text-transform: uppercase;
}

.nav-link-item a {
  color: $c-gray-dark;
  font-size: 0.9em;
  text-decoration: none;
}

.other-links-container {
  border-top: 1px solid $c-orange;

  .nav-items {
    margin-top: 1em;
  }
}
