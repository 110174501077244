
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        






































.text-color {
  color: $c-white;
  background-color: $c-main-secondary;
  padding: 1px 0;
}

.title {
  line-height: 1.3;
}

.content__excerpt {
  @extend %fw-thin;
  // prettier-ignore
  @include fluid(font-size, (xxs: 18px, l: 32px));
  font-weight: bold;
}

.content__text {
  ::v-deep {
    display: block;
    @include fluid(
      margin-bottom,
      (
        xxs: 20px,
        l: 25px,
      )
    );

    a {
      color: $c-white;
      font-weight: 600;
      text-decoration: none;

      &:hover {
        color: $c-main-tertiary;
      }
    }
  }
  &.second {
    @include fluid(
      padding-left,
      (
        m: 0px,
        l: 40px,
        xl: 30px,
      )
    );
  }
}
