
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        




























.steps-item {
  // prettier-ignore
  // @include fluid(margin-bottom, (m: 35px, l: 75px));
  @include fluid(
    padding-left,
    (
      s: 0,
      m: 25px,
      l: 50px,
    )
  );
  @include fluid(
    padding-right,
    (
      s: 0,
      m: 15px,
      l: 25px,
    )
  );
  @include mq($until: xl) {
    margin-bottom: 40px;
  }
  @include mq($until: s) {
    border-bottom: 1px solid $c-gray-lighter;
    margin-bottom: 0;
    padding-bottom: 40px;
  }
  @include mq(s) {
    display: flex;
    flex-direction: column;
    //padding-left: col(1, 8);
    //border-left: 1px solid $c-gray-lighter;
  }
  &::last-child {
    @include mq($until: s) {
      border-bottom: 0 solid transparent;
      padding-bottom: 0;
    }
  }
}

.steps-item__icon {
  max-width: 110px;
  height: auto;
  filter: none;
}

.steps-item__title {
  @extend %fw-bold;
  // prettier-ignore
  @include fluid(font-size, (xxs: 18px, xl: 24px));
  color: $c-gray-darker;
  font-family: $ff-default;
}

.steps-item__text {
  // prettier-ignore
  @include fluid(font-size, (xxs: 15px, xl: 18px));
  flex-grow: 1;
}
a.action,
.steps-item__link {
  // prettier-ignore
  @include fluid(margin-top, (xxs: 15px, m: 40px));
  justify-content: left;
  .action__label {
    font-size: $body-text;
  }
}
