
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        



















































.my-header-user-outer {
  position: relative;
}

.my-header-user {
  display: flex;
  align-items: center;
  height: 4rem;
  padding: 0 1rem 0 2rem;
  font-size: 1.3rem;
  border: 1px solid $c-gray-lighter;
  border-radius: 30px;
  cursor: pointer;

  > svg {
    fill: $c-orange;
  }
}

.my-header-user__icon {
  margin-right: 1rem;
}

.my-header-user__name {
  margin-right: 0.4rem;

  @include mq($until: $header-breakpoint) {
    display: none;
  }
}
