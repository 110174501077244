
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        









































.tip {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  //max-width: 28rem;
  //border-bottom: 1px solid $c-gray-light;
  padding-bottom: 0px;
  padding-top: 40px;

  @include mq(xl) {
    margin-top: 0;
    max-width: 28rem;
  }
  [class*='action--'][class*='--btn'] {
    display: flex;
    width: 100%;
    @include mq(m) {
      display: inline-flex;
      width: auto;
    }
  }
  + .tip {
    border-top: 1px solid $c-gray-light;
  }
}

.tip__icon {
  width: 1.8rem;
  height: 1.8rem;
  margin-bottom: 1rem;
  fill: $c-orange;
}

.tip__headline {
  margin-bottom: 1rem;
}

.tip__title {
  @extend %fw-medium;

  margin-bottom: 1rem;
  font-size: 1.8rem;
}

.tip__text {
  font-size: 1.5rem;

  ::v-deep {
    .video {
      margin-top: 2rem;
    }
  }
}
