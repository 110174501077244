
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        



















































































div.panel-accordion {
  margin-top: 15px;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-clip: border-box;
  border: 1px solid lightgrey;
  border-radius: 15px;

  &:nth-child(2) {
    margin-top: 5px;
  }
}

div.panel-accordion div.panel-accordion-title {
  padding-top: 15px;
  padding-left: 20px;
}

div.panel-accordion div.panel-accordion-title:hover {
  cursor: pointer;
}

div.panel-accordion div.panel-accordion-title.active {
  border-bottom: 1px solid lightgray;
}

div.panel-accordion div.panel-accordion-title span {
  color: #575756;
}

div.panel-accordion div.panel-accordion-title:hover span {
  font-weight: bolder;
}

div.panel-accordion div.panel-accordion-title.active .level-name {
  font-weight: bolder;
}

div.panel-accordion div.panel-accordion-title .side-item {
  display: inline-block;
  margin-left: 2rem;
}

div.panel-accordion div.panel-accordion-title .panel-accordion-arrow {
  fill: $c-gray-dark;
  float: right;
  margin-top: -10px;
  transform: none;
}

div.panel-accordion div.panel-accordion-title .panel-accordion-icon {
  fill: $c-orange;
  transform: none;
  margin-right: 1rem;
  max-width: 20px;
}

.meter-history .panel-accordion .panel-accordion-title .level-name {
  font-size: 16px !important;
  transition: 0.2s;

  @media screen and (max-width: 767px) {
    justify-content: flex-start;
  }
}

.meter-history {
  tr:not(:last-child) .table__td {
    border-bottom-style: dashed;
  }
}

.meter-history .panel-accordion {
  border-radius: 1rem;
  transition: 0.2s ease-out;
  box-shadow: 0 0 0 #18214d20;
  overflow: hidden;
}

.meter-history .panel-accordion.active {
  border-color: transparent;
  box-shadow: 0 0 24px #18214d20;
}

.meter-history .panel-accordion .panel-accordion-title {
  padding: 25px 30px;
  display: flex;
  align-items: center;

  &:hover span {
    font-weight: initial;
  }

  &.active .level-name {
    font-weight: initial;
  }

  span {
    font-size: 0.875em;
    max-width: 30rem;

    @media screen and (max-width: 767px) {
      max-width: 40vw;
    }
  }

  .side-item {
    position: absolute;
    left: 40rem;
    margin-left: unset;

    @media screen and (max-width: 767px) {
      left: unset;
      right: 20px;
    }
  }

  .badge-rounded {
    display: inline-block;
    height: 2.5rem;
    min-width: 2.5rem;
    padding: 0 0.4rem;
    margin-right: 5px;
    background-color: $c-green;
    border-radius: 2rem;
    font-weight: normal;
    color: white;
    text-align: center;
    line-height: 2.5rem;
  }

  .panel-accordion-arrow {
    fill: $c-gray-darkest;
    float: none;
    position: absolute;
    right: 10px;
    margin-top: 0;
  }
}

.meter-history .panel-accordion .panel-accordion-content {
  padding: 2rem 1.5rem;

  .table__th {
    color: $c-green;
    font-size: 1.5rem;
  }
}
