.table {
  width: 100%;

  @include mq($until: 'm') {
    display: block;
  }
}

.table__thead {
  @include mq($until: 'm') {
    display: none;
  }
}

.table__thead__th {
  @extend %fw-normal;

  font-size: 1.1rem;
  text-align: left;
  text-transform: uppercase;
  letter-spacing: 1px;

  @include mq(m) {
    display: table-cell;
  }
}

.table__body {
  @include mq($until: 'm') {
    display: flex;
    flex-wrap: wrap;
  }

  @include mq(s, m) {
    margin: 0 -2rem;
  }
}

.table__body__tr {

  @include mq($until: 'm') {
    display: flex;
    flex-direction: column;
    margin: 2rem 0;
  }

  @include mq($until: s) {
    flex-basis: 100%;
  }

  @include mq(s, m) {
    flex-basis: calc(50% - 4rem);
    margin: 2rem;
  }
}

.table__body__td {
  @include mq($until: 'm') {
    display: flex;
    justify-content: space-between;

    &::before {
      content: attr(data-label);
    }
  }

  @include mq(m) {
    padding: 2rem 0;
    border-bottom: 1px solid $c-gray-lightest;
  }
}
