html {
  font-size: calc(1em * 0.625);
}

body {
  /* sass-lint:disable-block no-vendor-prefixes */
  color: $c-gray-darker;
  font-family: #{$ff-default};
  font-size: $body-text;
  font-weight: 400;
  line-height: 1.5;

  @include mq(m) {
    font-size: $body-text-m;
  }
}

h1,
.h1 {
  @include fluid(
                  font-size,
                  (
                          xxs: 32px,
                          m: 48px,
                  )
  );

  color: $c-main-primary;
  display: block;
  font-family: #{$ff-alt};
  font-weight: 600;
  line-height: 1.1;
  text-transform: uppercase;

  strong {
    font-weight: 700;
  }

  .outline {
    color: $c-main-primary;
    -webkit-text-fill-color: $c-white;
    -webkit-text-stroke-width: 0.13rem;
    -webkit-text-stroke-color: $c-main-primary;
  }
}

h2,
.h2 {
  @include fluid(
                  font-size,
                  (
                          xxs: 28px,
                          m: 32px,
                  )
  );

  display: block;
  font-family: #{$ff-default};
  font-weight: 300;
  line-height: 1.2;

  /* stylelint-disable-next-line no-descending-specificity */
  strong {
    font-weight: 600;
  }

  &.primary {
    color: $c-main-primary;
  }
}

h3,
.h3 {
  @include fluid(
                  font-size,
                  (
                          xxs: 20px,
                          m: 24px,
                  )
  );

  color: $c-main-primary;
  font-family: #{$ff-default};
  font-weight: 700;
  line-height: 1.2;

  /* stylelint-disable-next-line no-descending-specificity */
  strong {
    font-weight: 700;
  }
}

h4,
.h4 {
  @include fluid(
                  font-size,
                  (
                          xxs: 18px,
                          m: 24px,
                  )
  );

  display: block;
  font-family: $ff-default;
  // font-size: 1.8rem; normal ??
  font-weight: 700;
  line-height: 1.2;
}

h5,
.h5 {
  @include fluid(
                  font-size,
                  (
                          xxs: 15px,
                          m: 18px,
                  )
  );

  display: block;
  font-family: $ff-default;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.5;
}

.lead {
  @include fluid(
                  font-size,
                  (
                          xxs: 16px,
                          m: 24px,
                  )
  );

  line-height: 1.5em;
}

.headline {
  @include fluid(
                  font-size,
                  (
                          xxs: 13px,
                          m: 15px,
                  )
  );

  display: block;
  color: $c-main-primary;
}

.number {
  @extend %fw-light;
  // prettier-ignore
  @include fluid(font-size, (xxs: 24px, m: 36px));
}

.tiny {
  @extend %fw-light;
  // prettier-ignore
  @include fluid(font-size, (xxs: 11px, m: 13px));
}

.small {
  // prettier-ignore
  @include fluid(font-size, (xxs: 13px, m: 15px));
}

input[type="checkbox"] + label,
input[type="radio"] + label {
  margin-left: 5px;
  @include fluid(
                  font-size,
                  (
                          xxs: 13px,
                          m: 15px,
                  )
  );
}

.bold {
  font-weight: bold;
}