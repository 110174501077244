
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        



















































































































.my-header {
  display: flex;
  align-items: center;
  height: $header-height;
  margin-top: -$header-height;
  padding-left: 2rem;
  background-color: $c-white;
  border-bottom: 1px solid $c-orange;

  > * + * {
    margin-left: 1.6rem;
  }

  @include mq($header-breakpoint) {
    padding-right: 2rem;

    .header-trigger {
      display: none;
    }
  }

  @include mq($header-breakpoint) {
    height: $header-height-big;
    margin-top: -$header-height-big;
  }
}

.my-header__logo {
  margin-right: auto;

  svg {
    width: 10rem;
    height: auto;
    fill: $c-main-primary;
  }
}

.header__nav__logo {
  position: relative;
  z-index: 2;
  display: block;
  margin-left: 2rem;

  svg {
    // prettier-ignore
    @include fluid(width, (xxxs: 60px, xl: 100px));

    height: auto;
    fill: $c-main-primary;
  }
}
