
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        















.text-only {
  text-align: center;
}

.inner {
  max-width: 84rem;
}

.title {
  margin-bottom: 1.5rem;
}
