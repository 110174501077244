
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        





















































.legal {
  position: relative;
  background-color: $c-legal-bg;
  border-top: 1px solid $c-gray-light;

  &__inner {
    display: flex;
    flex-direction: column;
    color: $c-white;
    font-size: 1.5rem;
    min-height: 11.4rem;
    flex-flow: column-reverse;

    @include mq(m) {
      flex-flow: unset;
    }

    a {
      color: $c-black;
      text-decoration: none;
    }

    .legal-resa-logo-area {
      display: none;
      align-items: center;
      margin: 0 3rem 0 3rem;
      line-height: 1rem;

      @include mq(m) {
        display: flex;
      }
    }

    @include mq(m) {
      flex-direction: row;
      justify-content: space-between;
      margin: 0 auto;
      padding: 0;
    }
  }

  &__items {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 3.5rem;
    margin-bottom: 3.5rem;

    li:first-child {
      margin-bottom: 2rem;

      a {
        color: $c-orange;
      }
    }

    @include mq(m) {
      flex-flow: row wrap;
      align-content: center;
      border-bottom: 0;
      margin-top: unset;
      margin-bottom: unset;

      li:first-child {
        margin-bottom: unset;

        a {
          color: inherit;
        }
      }
    }
  }

  &__item {
    display: inline-block;
    color: $c-black;
    font-size: 1.4rem;
    //line-height: 1.2;

    a {
      color: $c-gray-darker;
      @include mq(m) {
        color: $c-black;

        &:hover {
          color: $c-gray-darker;
        }
      }
    }

    @include mq(m) {
      margin-right: 2rem;
    }
  }

  &__social {
    width: auto;
    display: flex;
    align-items: center;
    margin-top: 3.5rem;

    @include mq(m) {
      margin-top: unset;
    }

    &__items {
      display: flex;
      padding-right: 2rem;
      @include mq(m) {
        padding-right: 3rem;
      }
    }

    &__item {
      margin-right: 1rem;
      @include mq(m) {
        margin-right: 2.6rem;
      }

      a {
        align-items: center;
        background: #ffffff;
        //box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
        border: 1px solid $c-gray-light;
        border-radius: 25px;
        color: $c-main-primary;
        display: flex;
        justify-content: center;
        width: 49px;
        height: 49px;

        svg {
          fill: $c-main-primary;
        }

        &:hover {
          svg {
            fill: $c-main-secondary;
          }
        }
      }

      svg {
        fill: $c-gray-medium;
      }
    }
  }

  &__scroll-top {
    background-color: $c-orange;
    border-radius: 25px;
    width: 49px;
    height: 49px;

    :hover {
      cursor: pointer;
      background-color: darken($c-orange, 3);
      border-radius: 25px;
    }

    svg {
      fill: $c-white;
    }
  }
}

.decorative {
  position: relative;

  .dots {
    &__wrapper {
      position: absolute;
    }

    &--desktop {
      @include mq($from: l) {
        bottom: -115px;
      }
      @include mq($until: l) {
        display: none;
      }
    }

    &--mobile {
      @include mq($until: l) {
        bottom: -22px;
      }
      @include mq($from: l) {
        display: none;
      }
    }
  }
}
