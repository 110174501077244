
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        







.loader,
[class*='loader--'] {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;

  img {
    max-width: 20rem;
    max-height: 20rem;
  }
}

[class*='loader--'][class*='--inline'] {
  display: inline-flex;
  vertical-align: middle;
  padding: 0 0 0 1rem;
}

[class*='loader--'][class*='--big'] {
  img {
    width: 25%;
    height: 25%;
  }
}
