
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        















































/* stylelint-disable no-descending-specificity, selector-max-combinators, selector-max-compound-selectors */
.related-links,
[class*='related-links--'] {
  > div {
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
  }
  &[class*='--column'] {
    > div {
      //border-top: 3px solid $c-black;
    }
  }
  .pl-s {
    padding-left: 40px;
  }
  .pr-s {
    padding-right: 40px;
  }
  @include mq(m) {
    &[class*='--column'] {
      > div {
        display: flex;
      }
    }
  }
}

.related-links__header {
  display: flex;
  justify-content: space-between;
  //margin-right: col(2, 22);
}

.related-links__title {
  font-size: 2.4rem;
  margin-bottom: 25px;
  white-space: nowrap;

  [class*='related-links--'][class*='--column'] & {
    // prettier-ignore
    //@include fluid(font-size, (xxs: 24px, xxl: 36px));
  }
  @include mq($until: xs) {
    white-space: normal;
  }
  @include mq(m) {
    [class*='related-links--'][class*='--column'] & {
      margin-bottom: 0px;
      // width: col(6, 22);
    }
  }

  @include mq(wrapper) {
    [class*='related-links--'][class*='--column'] & {
      // width: col(6, 20);
    }
  }
}

.related-links__list {
  margin: 0;
  padding: 0;
  transition: opacity 0.3s;

  .is-loading & {
    opacity: 0;
  }

  @include mq(xs) {
    [class*='related-links--'][class*='--column'] & {
      flex: 1;
      columns: 2;
      column-gap: col(2);
    }
  }

  @include mq(m) {
    //margin-top: -1.2rem;
  }
}

.related-links__list__item {
  @extend %border-radius;

  padding-bottom: 0;
  list-style-type: none;
  background: $c-white;
  border: 0px solid $c-gray-light;
  transition: all 0.3s;

  &::before {
    display: none;
  }

  &:hover {
    border: 0px solid $c-white;
    box-shadow: 0 10px 30px rgba($c-black, 0.1);
    transform: translateY(-2px);

    [class*='related-links--'][class*='--column'] &,
    [class*='related-links--'][class*='--list'] & {
      transform: none;
    }
  }

  [class*='action--link'] {
    margin: 0;
  }

  // prettier-ignore
  [class*='related-links--'][class*='--column'] & {
    break-inside: avoid-column;
    //border-bottom: 1px solid $c-gray-light !important; /* stylelint-disable-line declaration-no-important */

    // prettier-ignore
    ::v-deep [class*='action--link'] {
      justify-content: flex-start;

      .action__label {
        @extend %fw-bold;
        color: $c-main-primary;
      }

      svg {
        fill: $c-main-primary;
      }
    }
  }

  [class*='related-links--'][class*='--column'] &,
  [class*='related-links--'][class*='--list'] & {
    margin-bottom: 0;
    line-height: 3;
    border: 0;
    border-radius: 0;
    box-shadow: none;

    ::v-deep [class*='action--link'] {
      padding: 1.2rem 0;
    }
  }

  [class*='related-links--'][class*='--list'] & {
    border-bottom: 0.1rem solid $c-gray-light;

    ::v-deep [class*='action--link'] {
      .action__label {
        color: $c-main-primary;
      }
    }
  }

  ::v-deep [class*='action--link'] {
    // prettier-ignore
    @include fluid(padding-top, (xxs: 20px, m: 30px));
    // prettier-ignore
    @include fluid(padding-right, (xxs: 20px, m: 40px));
    // prettier-ignore
    @include fluid(padding-bottom, (xxs: 20px, m: 30px));
    // prettier-ignore
    @include fluid(padding-left, (xxs: 20px, m: 40px));

    display: flex;
    justify-content: space-between;
  }
}
