
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        





















































.cta__item {
  background-color: $c-footer-bg;
  border-bottom: 1px solid $c-white;
  color: $c-white;
  display: block;
  font-family: #{$ff-alt};
  font-size: 2rem;
  line-height: 1.3em;
  position: relative;
  text-decoration: none;

  > * {
    padding: 3rem col(2, 24);

    @include mq(m) {
      padding: 4rem col(2, 24) 5rem;
    }

    @include mq(l) {
      padding: 5rem col(1, 9) 5rem;
    }

    @include mq(wrapper) {
      padding: 5.5rem col(1, 7) 5rem;
    }
  }

  &--link {
    display: block;
  }

  &--btn {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    ::v-deep [class*='action--btn'] {
      border: 1px solid white;
    }
  }

  svg {
    display: block;
    fill: $c-white;
  }

  a {
    color: $c-white;
    text-decoration: none;
    transition: all 0.3s;
  }

  &.is-highlight {
    background-color: $c-main-secondary;
    //border-bottom: 0;
    ::v-deep svg {
      fill: $c-white !important;
    }

    @include mq(l) {
      // padding: 2rem 0 3rem;
      &::after {
        background-color: $c-main-secondary;
        border-bottom: 0;
      }
      &:hover {
        &::after {
          background-color: $c-main-secondary;
        }
      }
    }
  }

  &.cta-green {
    background-color: $c-green;
    //border-bottom: 0;
    ::v-deep svg {
      fill: $c-green !important;
      stroke: $c-white;
    }

    ::v-deep svg text {
      fill: $c-white !important;
      stroke: $c-white;
    }

    ::v-deep svg.cta__item__label__arrow {
      fill: $c-white !important;
      stroke: none !important;
    }

    @include mq(l) {
      // padding: 2rem 0 3rem;
      &::after {
        background-color: $c-green;
        border-bottom: 0;
      }
      &:hover {
        &::after {
          background-color: $c-green;
        }
      }
    }
  }

  @include mq(l) {
    font-size: 2.8rem;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 100%;
      box-sizing: content-box;
      width: 100vw;
      height: 100%;
      border-bottom: 1px solid $c-white;
      transition: all 0.3s;
    }
  }
}

.cta__item__label {
  align-items: center;
  display: flex;
  flex-direction: row;
  font-family: #{$ff-alt};
  font-weight: 600;
  font-size: 24px;
  text-transform: uppercase;
  width: 100%;
  @include mq(l) {
    max-width: 345px;
  }

  > span {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: space-between;
    line-height: 1.1em;
    //width: 300px;
    position: relative;
    padding-left: 1rem;
  }

  .label {
    /*max-width: 300px;
    width: 100%;*/
    //width: 200px;
    @include mq(xl) {
      margin-right: 15px;
      width: 260px;
    }
  }

  svg {
    fill: $c-white;
  }

  @include mq(l) {
    //flex-direction: column;
  }
}

svg.cta__item__label__arrow {
  //display: none;
  margin-bottom: 0;
  transition: transform 0.2s;
  transform: rotate(-90deg) translateY(-5px);

  @include mq(m) {
    //position: absolute;
    //right: 0;
    //bottom: -1rem;
    //display: block;
    fill: $c-white;
    //opacity: 0;
    //transform: rotate(-90deg) translateY(-5px);

    .cta__item:hover & {
      opacity: 1;
      transform: rotate(-90deg) translateY(0);
    }
  }

  @include mq(xl) {
    right: -3.5rem;
  }
}

.cta__item--btn .cta__item__btn {
  margin-top: 1rem;

  &:hover {
    background: $c-footer-bg;
    border: 1px solid $c-footer-bg;
  }
}
