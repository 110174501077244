
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        

















































div.img-block .image {
  text-align: center;
  width: 150px;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease-in-out;
}

div.img-block .no-image {
  text-align: center;
  border-radius: 30px;
  width: 150px;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease-in-out;
}

div.img-block .image img {
  max-width: 117px;
}

div.img-block:hover {
  cursor: pointer;
  transform: scale(1.02);
}

div.img-block.active:hover {
  cursor: default;
  transform: none;
}

div.img-block .no-image svg {
  fill: #d1a856;
  max-width: 117px;
}

div.img-block .title {
  width: 150px;
  text-align: center;
}

div.img-block:hover .title {
  color: $c-orange;
}

div.img-block .image,
div.img-block .no-image {
  @media only screen and (max-width: 767px) {
    width: 100%;
    word-break: break-word;
    max-height: 100px;
  }

  img {
    @media only screen and (max-width: 767px) {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}

div.img-block .title {
  @media only screen and (max-width: 767px) {
    width: 100%;
    word-break: break-word;
    margin: 10px 0 20px;
    line-height: 17px;
    font-size: 14px;
  }
}
