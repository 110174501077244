
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        



























.box-color {
  padding: 2rem;
  margin: 0;
  display: flex;

  .container {
    display: flex;
    align-items: start;
    width: 100%;
    margin: 0 2rem;

    .icon {
      flex: 0 0 auto;
      width: 2rem;
      height: 4.8rem;
      margin-right: 2rem;
    }

    .text {
      display: flex;
      align-items: center;
      flex: 1 1 auto;
      min-height: 4.8rem;
      p {
        flex: 1 1 auto;
        margin-right: 2rem;
      }

      @media screen and (max-width: 767px) {
        flex-wrap: wrap;
        .button {
          margin-top: 2rem;
        }
      }
    }

    .button {
      padding: 1rem 2rem;
      font-size: 1.3rem;
      text-decoration: none;
      border-radius: 3rem;
      transition: background-color 0.15s, color 0.15s, border 0.15s;
      flex: 0 0 auto;
    }
  }
}

.box-tip {
  border-left: 4px solid #f7a600;
  background-color: #fffaf2;
  color: #d3450d;
  .icon {
    fill: #d3450d;
    flex: 0 0 auto;
  }
  a.button {
    border: 2px solid #f7a600;
    background-color: #fffaf2;
    color: #d3450d;
  }
  a.button:hover {
    border: 2px solid #f7a600;
    background-color: #f7a600;
    color: #fff;
  }
}

.box-info {
  border-left: 4px solid #3e7a6b;
  background-color: #f5f8f7;
  color: #3e7a6b;
  .icon {
    fill: #3e7a6b;
  }
  a.button {
    border: 2px solid #3e7a6b;
    background-color: #f5f8f7;
    color: #3e7a6b;
  }
  a.button:hover {
    border: 2px solid #3e7a6b;
    background-color: #3e7a6b;
    color: #fff;
  }
}

.box-warning {
  border-left: 4px solid #d3450d;
  background-color: #fdf6f3;
  color: #d3450d;
  .icon {
    fill: #d3450d;
  }
  a.button {
    border: 2px solid #d3450d;
    background-color: #fdf6f3;
    color: #d3450d;
  }
  a.button:hover {
    border: 2px solid #d3450d;
    background-color: #d3450d;
    color: #fff;
  }
}
