
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        



































































.content__title {
  b {
    font-weight: 600;
  }
}

.header,
[class*='header--'] {
  /*@include fluid(
    padding-top,
    (
      xxs: 80px,
      xl: 160px,
    )
  );*/

  position: relative;
  display: flex;
  //align-items: center;

  &[class*='--normal'] {
    // prettier-ignore
    //@include fluid(padding-top, (xxs: 40px, xl: 125px));
    // prettier-ignore
    //@include fluid(padding-bottom, (xxs: 100px, xl: 85px));

    .content__title {
      @include mq(l) {
        margin-top: 50px;
      }
    }
  }

  &[class*='--cover'] {
    @include fluid(
      padding-top,
      (
        xxs: 75px,
        xl: 120px,
      )
    );
    @include mq(xl) {
      //height: 650px;
      height: auto;
      @include mq(xxl) {
        height: 650px;
        margin-top: -125px;
        padding-top: 150px;
      }
    }
    .content {
      padding-bottom: 40px;
    }
    .content__title {
      color: $c-main-secondary;
      line-height: 1em;
      margin-top: 0;
      text-transform: none;
      @include mq(m) {
        font-size: 80px;
      }
      ::v-deep {
        b {
          color: white !important;
          //text-shadow: 0px 1px 3px  rgba(247, 166, 0, 1);
          -webkit-text-stroke-width: 1px;
          -webkit-text-stroke-color: $c-main-secondary;
          -webkit-text-fill-color: #ffffff;
          @include mq(s) {
            -webkit-text-stroke-width: 1.7px;
          }
        }
      }
    }
    .content__text {
      line-height: 1.3;
      @include mq(l) {
        margin-top: -20px;
      }
    }
  }
}

.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  //margin-bottom: 2rem;

  ::v-deep .video {
    // prettier-ignore
    @include fluid(margin-top, (xs: 10px, xl: 60px));
  }
  // .header--normal & {
  // @include mq(xl) {
  //  flex-basis: 45%;
  //   max-width: 45%;
  // }
  //}
  .header--cover & {
    @include mq(xl) {
      flex-basis: 50%;
      max-width: 50%;
    }
  }

  @include mq(s) {
    margin-bottom: 0;
  }
}

.picture {
  width: 100%;

  img {
    display: block;
    max-width: 100%;
    max-height: 30rem;
    margin: 0 auto;
  }

  [class*='header--'][class*='--cover'] & {
    @include get-all-space;
    z-index: -1;

    img {
      @include image-fit($position: right);
      max-height: none;
      margin: 0;
    }

    &::before {
      @include get-all-space;
      content: '';
      z-index: 1;
      background-image: linear-gradient(
        145deg,
        rgba($c-white, 0.9) 60%,
        $c-transparent
      );
    }
  }

  [class*='header--'][class*='--normal'] & {
    img {
      border-radius: $border-radius;
      max-height: none;
    }
    @include mq($until: s) {
      margin-top: 40px;
    }
  }
  [class*='header--'][class*='--cover'] & {
    img {
      border-radius: revert;
    }
  }
  @include mq(s) {
    max-width: none;
    margin-top: 75px;
    img {
      max-height: none;
      margin: 0;
    }

    [class*='header--'][class*='--cover'] & {
      flex-basis: 100%;
      margin-left: 0;
      margin-top: 0;
      &::before {
        background-image: linear-gradient(
          to right,
          $c-white 50%,
          $c-transparent
        );
      }
    }
  }
  @include mq(xl) {
    margin-top: 0px;
  }
}

.preview {
  @include get-all-space;

  z-index: -1;

  &::before {
    @include get-all-space;
    content: '';
    z-index: 1;
    background-image: linear-gradient(
      145deg,
      rgba($c-white, 0.7) 60%,
      $c-transparent
    );
  }

  .preview__video {
    @include image-fit($position: center);
    max-height: none;
    margin: 0;
  }
}

.slot {
  @include mq(m) {
    margin-left: auto;
  }
}
