.header-trigger {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 6rem;
  height: 100%;
  margin-left: auto;
  color: $c-white;
  text-decoration: none;
  background-color: $c-main-primary;

  @include mq(#{$header-breakpoint}) {
    width: 16rem;
  }
}

.header-trigger__hamburger {
  span {
    display: block;
    width: 20px;
    height: 2px;
    margin-bottom: 3px;
    background-color: $c-white;
    transition: transform 0.3s, opacity 0.4s;
  }

  .is-open &,
  .is-sidebar-open & {
    span:nth-child(1) {
      /* stylelint-disable-next-line declaration-no-important */
      transform: translateY(5px) rotate(45deg) !important;
    }

    span:nth-child(2) {
      opacity: 0;
      /* stylelint-disable-next-line declaration-no-important */
      transform: translate(-20px) !important;
    }

    span:nth-child(3) {
      transform: translateY(-5px) rotate(-45deg);
    }
  }

  .header-trigger:hover & {
    span:nth-child(1) {
      transform: translate(-5px);
    }

    span:nth-child(2) {
      transform: translate(-10px);
    }
  }

  @include mq(#{$header-breakpoint}) {
    margin-right: 1rem;
  }
}

.header-trigger__label {
  @include mq($until: $header-breakpoint) {
    display: none;
  }
}
