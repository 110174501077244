
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        




























































.menu {
  display: flex;
}

.menu-trigger {
  display: none;
  padding: 3rem col(2, 24);

  svg {
    //display: block;
    display: none;
    margin-right: 0.7rem;
    fill: $c-main-primary;
  }

  @include mq(m) {
    padding: 4rem col(2, 24) 3rem;
  }

  @include mq($until: l) {
    display: flex;
    align-items: center;
    //width: 100%;
    width: 50%;
    color: $c-white;
    font-family: #{$ff-alt};
    font-size: 2.4rem;
    font-weight: 600;
    line-height: 1.3em;
    text-decoration: none;
    text-transform: uppercase;
    //border-top: 1px solid $c-main-border;
    .menu-inner & {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 3rem;
      background: $c-black;
      border-top: 0;

      svg {
        margin-bottom: 0;
        fill: $c-white;
      }
    }
  }
}

.menu-inner {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @include mq($until: l) {
    position: fixed;
    z-index: 11;
    top: 0;
    left: 0;
    display: none;
    overflow: auto;
    width: 100%;
    height: 100vh;
    background-color: $c-footer-bg;

    &.is-open {
      display: block;
    }
  }
}

.item__a {
  color: $c-white;
  font-size: 1.5rem;
  text-decoration: none;
}

.item__a:hover {
  color: $c-white;
  text-decoration: underline;
}

/*
.menu__items {
  list-style-type: disc;
  padding-left: 1.5rem;
}*/
.menu__categories {
  width: 100%;
  margin-bottom: 5rem;
  padding: 0;
  padding-right: col(2, 24);
  padding-left: col(2, 24);

  .menu__category {
    display: block;
    padding-bottom: 1.5rem;
    color: $c-white;
    font-size: 1.8rem;
    font-weight: 700;
    text-transform: uppercase;
  }

  ::v-deep [class*='action--link'] {
    .action__label {
      @extend %fw-normal;

      color: $c-white !important;
      line-height: 1.35;
      transition: all 0.3s;

      &:hover {
        color: $c-white !important;
        text-decoration: underline;
      }
    }
  }

  @include mq(l) {
    flex: 0 0 33%;
    max-width: calc((100% / 3) - 3rem);
    padding: 0;
  }
}

.menu__item {
  color: $c-white;
  font-size: 1.5rem;
  line-height: 1.3;
  text-decoration: none;
  transition: all 0.3s;
}

.item__a {
  color: $c-white;
  font-size: 1.5rem;
  text-decoration: none;
}

.item__a:hover {
  color: $c-white;
  text-decoration: underline;
}
