.wysiwyg,
[class*='wysiwyg--'] {
  h1,
  .h1,
  h2,
  .h2 {
    @include fluid(
                    margin-bottom,
                    (
                            xxs: 30px,
                            m: 50px,
                    )
    );
  }

  h3,
  .h3,
  h4,
  .h4 {
    @include fluid(
                    margin-bottom,
                    (
                            xxs: 20px,
                            m: 30px,
                    )
    );
  }

  p {
    // prettier-ignore
    @include fluid(margin-bottom, (xxs: 20px, m: 30px));
  }

  hr {
    // prettier-ignore
    @include fluid(margin-top, (xxs: 20px, m: 50px));
    // prettier-ignore
    @include fluid(margin-bottom, (xxs: 20px, m: 50px));

    height: 2px;
    background: $c-black;
    border: 0;
  }

  ul,
  ol {
    margin-top: 4rem;
    margin-bottom: 6rem;
  }

  ol {
    list-style: none;
    counter-reset: li;
  }

  ul li {
    position: relative;
    padding-bottom: 1.5rem;
    line-height: 1.4em;
    list-style-type: none;

    &::before {
      content: '';
      position: absolute;
      top: 0.8em;
      left: -20px;
      width: 5px;
      height: 5px;
      background: $c-orange;
      border-radius: 50%;
    }
  }

  ol li {
    position: relative;
    padding-bottom: 1.5rem;
    counter-increment: li;

    &::before {
      content: counter(li) '. ';
      display: inline-flex;
      margin-right: 1rem;
      color: $c-orange;
    }
  }

  a {
    color: $c-main-primary;
    text-decoration: none;
    // background-image: linear-gradient($c-main-primary, $c-main-primary);
    // background-position: 0% 100%;
    // background-repeat: no-repeat;
    // background-size: 0% 1px;
    // transition: background-size 0.3s;

    // &:hover {
    //   background-size: 100% 2px;
    // }
  }

  .table-wrapper {
    overflow-x: auto;
  }

  table {
    min-width: 76rem;

    thead {
      @include fluid(
                      font-size,
                      (
                              xxs: 9px,
                              xl: 11px,
                      )
      );

      color: $c-main-primary;
      text-transform: uppercase;
      letter-spacing: 1px;
    }

    tbody {
      @include fluid(
                      font-size,
                      (
                              xxs: 15px,
                              xl: 18px,
                      )
      );

      color: $c-gray-darker;
      line-height: 3.2rem;
    }

    tr {
      border-bottom: 1px solid $c-gray-light;
    }

    td {
      padding: 3rem 0;
    }
  }

  .cta-rich {
    margin-bottom: 4rem;
  }

  [class*='action--'] {
    margin-bottom: 6rem;
  }
}
