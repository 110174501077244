
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        

















































.app {
  padding-top: $header-height;

  @include mq($header-breakpoint) {
    padding-top: $header-height-big;
  }

  &.commune {
    padding-top: $header-height-commune;
    background: left bottom 230px
      url('../src/assets/svg/commune/dots-mobile.svg') no-repeat;
    background-size: 200px;

    @include mq($header-breakpoint) {
      padding-top: $header-height-commune;
      background: left bottom 70px
        url('../src/assets/svg/commune/dots-mobile.svg') no-repeat;
      background-size: 200px;
    }
  }
}
