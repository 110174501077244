
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        





























.gaz {
  path {
    fill: $c-green;
    will-change: opacity;
  }
}
