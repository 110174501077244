
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        



































.list__title {
  display: block;
  padding-bottom: 1.5rem;
  color: $c-main-primary;
  font-family: #{$ff-default};
  font-size: 1.5rem;
  font-weight: 400;
}

.list__items {
  padding: 0 0 1.5rem;
  list-style: none;
  border-bottom: 1px solid $c-gray-light;

  .is-category & {
    display: flex;
    flex-wrap: wrap;
  }
}

.list__item {
  .is-category & {
    margin: 0 1rem 1rem 0;
  }

  &::before {
    display: none;
  }
}

.list__item a,
.list__item span {
  display: flex;
  align-items: center;
  color: $c-black;
  font-size: 1.3rem;
  line-height: 1.5;
  text-decoration: none;
  transition: all 0.3s;

  .icon {
    margin-left: 1.3rem;
    fill: $c-main-primary;
  }

  .is-category & {
    padding: 0.7rem 1.4rem;
    color: $c-gray-dark;
    font-size: 0.9rem;
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    background-color: $c-gray-lightest;
    border-radius: 6px;
  }

  &:hover {
    color: $c-main-primary;
  }
}
