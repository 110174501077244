
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        
































































































































































































































































































































































.mega-menu {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;

  @include mq(#{$header-breakpoint}) {
    margin: 0 auto;
  }
}

.mega-menu__container {
  position: relative;
  display: none;
  height: 100vh;
  max-height: calc(100vh - #{$header-height});
  background-color: $c-white;
  border-top: 1px solid $c-header-border;
  transition: transform 0.15s;

  a {
    text-decoration: none;
  }

  .is-open & {
    display: block;
  }

  @include mq($until: nav) {
    &.is-sub-open {
      transform: translateX(-100%);
    }
  }

  @include mq(#{$header-breakpoint}) {
    overflow: auto;
    max-height: auto;
    padding-bottom: 0;

    &.is-sub-open {
      transform: none;
    }
  }
}
.item__white {
  color: $c-white;
}
.item__black {
  color: #595958;
}

.mega-menu__container__inner {
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  height: 100%;
  max-height: calc(100vh - #{$header-height});

  @include mq(nav) {
    flex-direction: row;
    justify-content: space-between;
    overflow: hidden;
    margin: 0 auto;

    &.is-sub-open {
      transform: none;
    }
  }

  @include mq(#{$header-breakpoint}) {
    //padding-left: 4rem;
  }

  @include mq(xxxl) {
    //padding-left: 14rem;
  }
}

.mega-menu__main {
  background-color: $c-main-primary;
  padding: 3rem 0 0 0;
  transition: transform 0.15s;
  @include mq(s) {
    padding: 3rem 0rem 0 0;
  }
  @include mq(nav, #{$header-breakpoint}) {
    .is-sub-open & {
      transform: translateX(-100%);
    }
  }
  @include mq(nav) {
    position: relative;
    flex-basis: 100%;
  }
  @include mq(#{$header-breakpoint}) {
    flex-basis: auto;
    width: col(6, 22);
    padding: 4rem 0 2rem 0;
    //padding: 4rem col(1, 22) 4rem 0;
    //padding-left: 4rem;
    border-right: 1px $c-gray-lighter solid;
  }
  @include mq(xxxl) {
    //padding: 8rem col(1, 22) 8rem 0;
    //padding-left: 14rem;
    //padding: 8rem col(1, 22) 8rem 14rem;
    padding: 8rem 0 6rem 0;
  }
}

.mega-menu__title {
  display: block;
  font-size: 2.4rem;
  font-weight: 400;
  margin-bottom: 2rem;
  padding-left: 3rem;

  @include mq(#{$header-breakpoint}) {
    margin-bottom: 3rem;
  }
}

.mega-menu__sub {
  @include mq(#{$header-breakpoint}) {
    flex: 1;
  }
}

.mega-menu__sub-item {
  position: absolute;
  top: 0;
  left: 100%;
  width: 100%;
  height: 100%;
  padding: 0 1rem;
  background-color: $c-white;
  opacity: 0;

  .is-active & {
    z-index: 10;
    opacity: 1;
  }

  @include mq(s) {
    padding: 0 4rem;
  }

  @include mq(#{$header-breakpoint}) {
    width: calc(100vw - 127.5%);
    height: 100%;
    padding: 4rem col(1, 9) 4rem col(2, 9);
    background: transparent;
    transition: opacity 0.2s $ease;
  }
  @include mq(xxxl) {
    padding: 8rem col(1, 9) 8rem col(2, 9);
  }
}

.mega-menu__sub-item__close {
  display: flex;
  align-items: center;
  padding: 2rem;
  color: $c-gray;
  font-size: 1.5rem;

  @include mq(#{$header-breakpoint}) {
    display: none;
  }
}

.mega-menu__sub-item__close__arrow {
  margin-right: 1rem;
  fill: $c-gray;
  transform: rotate(90deg);
}

.mega-menu__sub-item__container {
  overflow: auto;
  height: calc(100% - 7rem);
  padding: 2rem;
  background-color: $c-white;

  @include mq(#{$header-breakpoint}) {
    height: 100%;
    padding: 0;
  }
}

.mega-menu__cta {
  @include mq(#{$header-breakpoint}) {
    width: 42rem;
  }
}

.mega-menu__small-label {
  color: $c-white;
  svg {
    fill: $c-white;
    margin-left: 10px;
    vertical-align: middle;
  }
  @include mq(#{$header-breakpoint}) {
    padding-left: 6rem;
    padding-right: col(1, 22);
  }
}

.mega-menu__main__item {
  border-bottom: 2px solid $c-white;
  display: block;
  font-size: 2.4rem;
  font-weight: 400;
  line-height: 1.2;
  padding: 2rem 3rem;
  transition: opacity 0.3s $ease;
  > a {
    display: block;
    color: $c-white;
  }
  /*
  &:hover {
    text-decoration: none;
  }*/
  &:hover,
  &.is-active {
    background-color: $c-white;
    color: $c-main-primary;

    text-decoration: none;
    > a {
      color: $c-main-primary;
    }
    .mega-menu__main__item__subtitle {
      color: $c-main-primary;
    }
  }
  .mega-menu__main__item__subtitle {
    // prettier-ignore
    @include fluid(font-size, (xxs: 15px, xl: 18px));
    display: block;
    color: $c-white;
  }
  @include mq(#{$header-breakpoint}) {
    padding-left: 6rem;
    padding-right: col(1, 22);
    /*&:hover,
    &.is-active {
      background-color: $c-white;
      color: $c-main-primary;
      > a {
        color: $c-main-primary;
      }
      .mega-menu__main__item__subtitle {
        color: $c-main-primary;
      }
    }*/
  }
}

.mega-menu__main__item__title {
  @extend %fw-thin;
  // prettier-ignore
  @include fluid(font-size, (xxs: 26px, xl: 38px));

  font-family: $ff-default;

  @include mq(#{$header-breakpoint}) {
    position: relative;
  }
  .is-active & {
    font-weight: 600;
  }
}

.mega-menu__main__item__arrow {
  float: right;
  margin-top: 1.5rem;
  transform: rotate(-90deg);
  transition: transform 0.2s;

  opacity: 0;
  transform: rotate(-90deg) translateY(-5px);
  :hover &,
  .is-active & {
    fill: $c-main-primary;
  }
  .is-active &,
  .mega-menu__main__item:hover & {
    opacity: 1;
    transform: rotate(-90deg) translateY(0);
  }
  /*
  @include mq(#{$header-breakpoint}) {
    opacity: 0;
    transform: rotate(-90deg) translateY(-5px);
    :hover &,
    .is-active & {
      fill: $c-main-primary;
    }

    .is-active &,
    .mega-menu__main__item:hover & {
      opacity: 1;
      transform: rotate(-90deg) translateY(0);
    }
  }*/
}

.mega-menu__aside {
  position: relative;
  z-index: 20;
  background: $c-main-primary;

  @include mq(nav) {
    display: flex;
    flex-direction: column;
    width: col(5.5);
    border-left: 1px $c-gray-lighter solid;
  }
}

.mega-menu__aside__cta {
  background-image: url('../../assets/images/bg-pannes.png');
  background-size: cover;
  background-position: center;
  border-bottom: 2px solid white;
  color: $c-white;
  display: flex;
  flex-direction: column;
  font-family: $ff-alt;
  position: relative;
  width: 100%;
  &:first-of-type {
    background-image: url('../../assets/images/bg-releve-index.png');
  }
  .color-bg {
    align-items: center;
    background-color: rgba($c-main-secondary, 0.9);
    display: flex;
    flex-direction: row;
    flex: 1;
    height: 100%;
    padding: 3rem;
    width: 100%;
    @include mq(s) {
      padding: 3rem 3rem;
    }
    @include mq(m) {
      align-items: center;
      flex-direction: column;
      height: 100%;
      justify-content: center;
    }
    @include mq(#{$header-breakpoint}) {
      //padding: 0 col(2, 6) 0 col(1, 6);
      padding: 3rem 7rem 3rem 5rem;
    }
  }
  .aside-title {
    flex: 1;
    font-size: 2.8rem;
    font-weight: 600;
    line-height: 1.1em;
    text-transform: uppercase;
    @include mq(m) {
      text-align: center;
    }
  }
  /* stylelint-disable-next-line no-descending-specificity */
  > span {
    position: relative;
    padding-right: 3rem;
  }

  .icon {
    //margin-bottom: 1rem;
    fill: $c-white;
    &.ico-aside {
      height: 50px;
      width: 50px;
      @include mq(m) {
        height: 70px;
        width: 70px;
      }
    }
  }
  @include mq(nav) {
    flex: 1;
    min-width: 28rem;

    &:first-of-type {
      border-top: 0;
    }
  }

  @include mq(#{$header-breakpoint}) {
    // min-height: 24rem;
    /*padding: 0 col(2, 6) 0 col(1, 6);*/
    font-size: 2.8rem;
    font-weight: 500;
    line-height: 1.3;

    &:last-child {
      border-bottom: 0;
    }
  }
}

.mega-menu__aside__cta__arrow {
  //display: none;
  transition: transform 0.2s;
  transform: rotate(-90deg) translateY(-5px);
  /*@include mq(m) {
    position: absolute;
    right: 0;
    bottom: -0.5rem;
    display: block;
    opacity: 0;
    transform: rotate(-90deg) translateY(-5px);
    .mega-menu__aside__cta:hover & {
      opacity: 1;
      transform: rotate(-90deg) translateY(0);
    }
  }*/

  @include mq(xl) {
    right: -3.5rem;
  }
}
.row-aside {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex: 1;
  column-gap: 20px;

  @include mq(m) {
    flex: initial;
    margin-top: 20px;
  }
}
.mega-menu__aside__cta__link {
  @include get-all-space;
  z-index: 1;
  opacity: 0;
}

.mega-menu__main__links {
  margin: 0;
  padding: 3rem 4rem 0 4rem;

  .mega-menu__main & {
    display: none;

    @include mq(m) {
      display: block;
    }
    @include mq(#{$header-breakpoint}) {
      padding: 3rem 4rem 0 6rem;
    }
  }

  .mega-menu__aside & {
    display: block;
    margin-top: 4rem;
    padding: 0 3rem;

    @include mq(m) {
      display: none;
    }
  }
}

.mega-menu__main__links__item {
  ::v-deep [class*='action--link'] {
    /* stylelint-disable-next-line selector-max-combinators, selector-max-compound-selectors */
    .action__label {
      color: $c-white !important; // stylelint-disable-line declaration-no-important
    }
  }

  ::v-deep [class*='action--btn'] {
    padding: 0;
    background: transparent;

    &:hover {
      background: transparent !important; // stylelint-disable-line declaration-no-important
    }

    /* stylelint-disable-next-line selector-max-combinators, selector-max-compound-selectors */
    .action__label {
      color: $c-white !important; // stylelint-disable-line declaration-no-important
    }

    /* stylelint-disable-next-line selector-max-combinators, selector-max-compound-selectors */
    .icon {
      fill: $c-white !important; // stylelint-disable-line declaration-no-important
    }
  }

  @include mq(#{$header-breakpoint}) {
    list-style-type: none;
    font-size: 1.8rem;
    font-weight: 400;

    .icon {
      margin-left: 1rem;
      fill: $c-white;
      transform: rotate(-90deg);
    }

    ::v-deep [class*='action--link'] {
      /* stylelint-disable-next-line selector-max-combinators, selector-max-compound-selectors */
      .action__label {
        color: $c-white !important; // stylelint-disable-line declaration-no-important
      }

      /* stylelint-disable-next-line selector-max-combinators, selector-max-compound-selectors */
      .icon {
        margin-left: 0;
        fill: $c-white !important; // stylelint-disable-line declaration-no-important
      }
    }

    ::v-deep [class*='action--btn'] {
      /* stylelint-disable-next-line selector-max-combinators, selector-max-compound-selectors */
      .action__label {
        color: $c-white !important; // stylelint-disable-line declaration-no-important
      }

      /* stylelint-disable-next-line selector-max-combinators, selector-max-compound-selectors */
      .icon {
        margin-left: 0;
        fill: $c-white !important; // stylelint-disable-line declaration-no-important
      }
    }
  }

  @include mq(xxxl) {
    margin-bottom: 1rem;
  }
}

.mega-menu__sub-item__links__item {
  font-size: 1.8rem;

  /* stylelint-disable selector-max-combinators, selector-max-compound-selectors */
  ::v-deep [class*='action--link'] {
    .action__label {
      color: $c-gray-darker !important; // stylelint-disable-line declaration-no-important
      font-weight: 400;
      line-height: 1.4;

      @include mq(m) {
        font-size: 2rem;
      }
    }

    .action__icon-wrapper {
      opacity: 0;
      transform: translateX(-5px);
      transition: transform 0.2s;
    }

    .icon {
      fill: $c-main-primary !important; // stylelint-disable-line declaration-no-important
    }

    &:hover {
      .action__icon-wrapper {
        opacity: 1;
        transform: translateX(0);
      }

      .icon {
        animation: none !important; // stylelint-disable-line declaration-no-important
      }
    }
  }
  /* stylelint-enable selector-max-combinators, selector-max-compound-selectors */

  &:not(:last-child) {
    margin-bottom: 2rem;
  }

  @include mq(#{$header-breakpoint}) {
    margin-bottom: 2rem;
    color: $c-gray-medium;

    a:hover {
      .icon {
        opacity: 1;
        transform: translateX(8px);
      }
    }
  }
}

.action--link--icon--arrowhead-right {
  font-size: 1.5rem;
}

.mega-menu__social {
  padding: 0 3rem;
  .mega-menu__main & {
    display: none;
    @include mq(m) {
      display: block;
      padding: 0 6rem;
    }
  }
  .icon,
  .social-icon {
    fill: $c-white;
  }
  .mega-menu__aside & {
    display: block;
    padding: 0 3rem;

    @include mq(m) {
      display: none;
    }
  }

  a {
    display: inline-flex;
    padding-right: 1.5rem;
  }
}
