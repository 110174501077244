
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        

























































.breadcrumb {
  position: relative;
  z-index: 1;
  width: 100%;
}

.breadcrumb-list {
  @include fluid(
    font-size,
    (
      xxs: 12px,
      xxl: 16px,
    )
  );

  display: flex;
  flex-wrap: wrap;
}

.breadcrumb__item {
  color: $c-gray-darker;

  &::first-letter {
    text-transform: capitalize;
  }

  a {
    color: $c-gray-darker;
    text-decoration: none;
    transition: all 0.3s;

    &:hover {
      color: $c-black;
    }
  }

  svg {
    margin: 0 1rem;
    fill: $c-gray-darker;
    transform: rotate(270deg);
  }

  &:first-child {
    a {
      color: $c-main-primary;
    }
  }

  &:last-child {
    svg {
      display: none;
    }
  }
}

.commune {
  .breadcrumb {
    &__item {
      a {
        color: $c-gray-darker;
        text-decoration: underline;
      }

      &:first-child {
        a {
          color: inherit;
        }
      }

      &:last-child {
        color: $c-main-primary;
        text-decoration: none;
      }
    }
  }
}
