
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        












.lines {
  position: relative;
  height: 300px;
  transform: rotate(-45deg);
}

.line {
  position: absolute;
  width: 100px;
  height: 3px;
  background: $c-lemon;
  will-change: transform;
  transform: scaleX(0);
  transform-origin: center left;

  &.is-reversed {
    transform-origin: center right;
  }
}
