
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        































































.message,
[class*='message--'] {
  // prettier-ignore
  @include fluid(padding-top, (xxs: 10px, m: 30px));
  // prettier-ignore
  @include fluid(padding-right, (xxs: 20px, m: 70px));
  // prettier-ignore
  @include fluid(padding-bottom, (xxs: 10px, m: 30px));
  // prettier-ignore
  @include fluid(padding-left, (xxs: 20px, m: 70px));

  position: relative;
  // margin: 2rem 0;
  background: $c-white;
  border: 1px solid $c-black;

  &.is-success,
  &[class*='message--'][class*='--success'] {
    border: 1px solid $c-green;
  }

  &.is-information,
  &[class*='--information'] {
    border: 1px solid $c-orange;
  }

  &.is-alert,
  &.is-error,
  &[class*='--alert'],
  &[class*='--error'] {
    border: 1px solid $c-red;
  }

  &.is-warning,
  &[class*='--warning'] {
    border: 1px solid $c-orange-light;
  }
}

.label {
  position: absolute;
  top: 0;
  left: 5%;
  display: flex;
  align-items: center;
  padding: 3px 10px;
  line-height: 1;
  background: $c-white;
  transform: translateY(-50%);

  /* stylelint-disable no-descending-specificity */
  .is-success &,
  [class*='message--'][class*='--success'] & {
    color: $c-green;
    fill: $c-green;
  }

  .is-alert &,
  [class*='message--'][class*='--alert'] &,
  .is-error &,
  [class*='message--'][class*='--error'] & {
    color: $c-red;
    fill: $c-red;
  }

  .is-warning &,
  [class*='message--'][class*='--warning'] & {
    color: $c-orange-light;
    fill: $c-orange-light;
  }

  .is-information &,
  [class*='message--'][class*='--information'] & {
    color: $c-orange;
    fill: $c-orange;
  }
  /* stylelint-enable no-descending-specificity */

  svg {
    width: 2.4rem;
    height: 2.4rem;
    margin-right: 0.5rem;
  }
}

.content {
  // prettier-ignore
  @include fluid(margin-top, (xxs: 35px, m: 50px));
  // prettier-ignore
  @include fluid(padding-top, (xxs: 35px, m: 50px));

  border-top: $c-gray-light solid 1px;
}

.content__label {
  margin-right: 10%;
  margin-bottom: 2rem;
}

.unread {
  font-size: 14px;
  margin-left: 20px;
  color: $c-gray-light;
}

.message__actions {
  display: flex;
  align-items: center;
}
