.g-has-error,
[class*='g-has-error--'] {
  border: 1px solid $c-red;
}

.g-error {
  padding: 1rem 2rem;
  color: $c-red;
  background: $c-pink-light;
  border-radius: 4px;
}
