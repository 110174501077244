
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        























.footer {
  position: relative;
  overflow: hidden;
  //background-color: $c-footer-bg;
}

.commune .footer {
  overflow: unset;
}

.footer-inner {
  display: flex;
  flex-direction: column;
  //max-width: 168rem;
  color: $c-white;

  @include mq(l) {
    flex-direction: row;
    justify-content: space-between;
    margin: 0 auto;
  }
}

.footer__main {
  width: 100%;
  padding: 0;
  background-image: url('../../assets/images/footer-bg-img.png');
  background-position: top right;
  background-repeat: no-repeat;
  background-size: cover;
  border-bottom: 1px solid rgba($c-white, 1);

  .main-color {
    //background-color: rgba($c-main-primary, 0.9);
    @include mq($until: l) {
      background-color: rgba($c-main-primary, 1);
      display: flex;
      flex-direction: row;
    }
    @include mq(l) {
      background-color: rgba($c-main-primary, 0.9);
      padding: 8rem col(2, 24) 8rem;
    }
  }

  @include mq(l) {
    width: col(16, 24);
    //padding: 8rem col(2, 24) 11rem;
    border-right: 1px solid rgba($c-white, 0.5);
    border-bottom: 0;
  }

  @include mq(wrapper) {
    width: col(17, 24);
  }
}

.footer__main__logo {
  display: inline-block;
  //padding: 4rem col(2, 24) 3rem;
  padding: 3rem col(2, 24) 2rem;

  svg {
    width: 90px;
    height: auto;
    fill: $c-white;
  }

  @include mq(l) {
    margin-bottom: 3rem;
    padding: 0;
  }
}

.footer__aside {
  background-color: $c-footer-bg;
  width: 100%;

  @include mq(l) {
    width: col(9, 24);
    padding-right: 0;
    padding-left: 0;
  }

  @include mq(wrapper) {
    width: col(7, 24);
  }
}
