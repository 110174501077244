
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        














.overlay-panel,
[class*='overlay-panel--'] {
  @include get-all-space;

  position: fixed;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: hidden;

  &.is-visible {
    visibility: visible;
  }

  &[class*='--aside'] {
    justify-content: flex-end;
  }
}

.bg {
  @include get-all-space;

  background: rgba($c-black, 0.6);
}

.content {
  position: relative;
  z-index: 1;
  overflow: auto;
  width: auto;
  max-height: 100vh;

  [class*='overlay-panel--'][class*='--aside'] & {
    width: 100vw;
    max-width: 84rem;
    height: 100vh;
    background: $c-white;
    transform: translateX(100%);
    transition: transform 0.3s;
  }

  [class*='overlay-panel--'][class*='--aside'].is-visible & {
    transform: translateX(0);
  }
}

.close {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 5rem;
  height: 5rem;
  padding: 0;
  background: $c-black;
  border: 0;
  cursor: pointer;

  .icon {
    width: 2.4rem;
    height: 2.4rem;
    fill: $c-white;
  }
}
