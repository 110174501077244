
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        











































.cta-custom,
[class*='cta-custom--'] {
  // prettier-ignore
  @include fluid(padding-top, (xxs: 20px, m: 30px));
  // prettier-ignore
  @include fluid(padding-right, (xxs: 15px, m: 25px));
  // prettier-ignore
  @include fluid(padding-bottom, (xxs: 20px, m: 40px));
  // prettier-ignore
  @include fluid(padding-left, (xxs: 20px, m: 40px));
  border-radius: $border-radius;
  box-shadow: $box-shadow;
  position: relative;
  text-align: left;

  ::v-deep svg.yellow {
    fill: transparent;
    stroke: $c-yellow;
  }

  &.yellow {
    box-shadow: 0 1px 5px 0 $c-yellow;

    ::v-deep [class*='content__btn'] {
      &:hover {
        background: transparent !important; // stylelint-disable-line
      }
    }

    ::v-deep [class*='content__link'] {
      // stylelint-disable-next-line
      .action__label {
        color: $c-white;
        border-color: $c-white;
      }

      // stylelint-disable-next-line
      .action__icon {
        fill: $c-white;
      }
    }
  }

  &[class*='--large'] {
    // prettier-ignore
    @include fluid(padding-top, (xxs: 65px, m: 120px));
    // prettier-ignore
    @include fluid(padding-right, (xxs: 55px, m: 140px));
    // prettier-ignore
    @include fluid(padding-bottom, (xxs: 65px, m: 120px));
    // prettier-ignore
    @include fluid(padding-left, (xxs: 55px, m: 140px));
  }
}

.ctaCustom :hover {
  cursor: pointer;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
}

.content__icon {
  margin-bottom: 1rem;
  margin-left: -5px;

  &.white {
    fill: $c-white;
  }

  &.orange {
    fill: $c-orange;
  }

  &.yellow {
    fill: $c-yellow;
  }

  &.green {
    fill: $c-green;
  }
}

.content__title {
  @extend %fw-light;
  // prettier-ignore
  @include fluid(font-size, (xxs: 24px, m: 30px));
  color: $c-main-primary;
  font-family: $ff-default;
  font-weight: 400;
  line-height: 1;
  padding-top: 25px;
  margin-left: 20px;

  [class*='cta-custom--'][class*='--large'] & {
    // prettier-ignore
    @include fluid(font-size, (xxs: 24px, m: 46px));
  }
}

.content__text {
  color: $c-gray-darker;
}
