
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        





















































.notifications {
  position: relative;
  z-index: 10;
}

.list {
  position: fixed;
  top: 15%;
  left: 50%;
  width: 100%;
  max-width: 80vw;
  transform: translateX(-50%);

  @include mq(m) {
    max-width: 60rem;
  }
}

.list__item {
  position: inherit;
  z-index: 2;
  display: flex;
  width: 100%;
  padding: 2rem 2.5rem;
  background: $c-white;
  border: $c-gray-light 1px solid;
  border-radius: 3px;
  transition: transform 0.3s $ease-out-quad, opacity 0.5s;

  &.is-viewed {
    // stylelint-disable-next-line
    transform: translateY(20px) !important;
    opacity: 0;
  }

  &.information {
    border: $c-green 1px solid;
  }

  &.warning {
    border: $c-yellow 1px solid;
  }

  &.danger {
    border: $c-red 1px solid;
  }

  &:not(.is-first) {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    height: 100%;
  }
}

.list__item__icon {
  flex-shrink: 0;
  width: 3rem;
  height: 3rem;
  margin-right: 2rem;
  fill: $c-orange;
}

.list__item__content {
  flex: 1;
}

.list__item__title {
  @extend %fw-bold;

  display: flex;
}

.list__item__text {
  font-size: 1.4rem;
  overflow-y: auto;
  max-height: 55vh;
  height: 100%;
}

.list__item__close {
  width: 1.5rem;
  height: 1.5rem;
  margin-left: auto;
  padding: 0;
  background: transparent;
  border: 0;
  cursor: pointer;
  min-width: 1.5rem;
  min-height: 1.5rem;

  @media screen and (max-width: 767px) {
    position: relative;
    top: -5rem;
  }

  svg {
    width: 100%;
    height: 100%;
  }
}

.commune-notif li {
  background: #ffffff;
  box-shadow: 0px 0px 24px rgba(24, 33, 77, 0.2);
  border-radius: 15px;
  border: 0 !important;
  padding: 3.5rem;

  @media screen and (max-width: 767px) {
    flex-direction: column;
    gap: 20px;
  }
}
