@mixin fatline {
  content: '';
  display: block;
  height: 1px;
  margin-top: 0.5em;
  background-color: $c-black;
}

.fatline,
[class*='fatline--'][class*='--after'] {
  &::after {
    @include fatline;

    bottom: 0;
  }
}

[class*='fatline--'][class*='--before'] {
  &::before {
    @include fatline;

    top: 0;
  }
}

[class*='fatline--'][class*='--absolute'] {
  position: relative;

  &::before,
  &::after {
    position: absolute;
    width: 100%;
  }
}
