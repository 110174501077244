
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        

























































.list-item-close {
  width: 2.4rem;
  height: 2.4rem;
  margin-left: 3rem;
  padding: 0;
  background: transparent;
  border: 0;
  cursor: pointer;
  flex: 0 0 auto;

  .icon {
    width: 100%;
    height: 100%;
    fill: #000000;
  }
}
