
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        





























































.intro {
  @include mq(m) {
    margin-bottom: 0;
  }
}

.content {
  @include mq(s) {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
}
/*
.text-enum .content__title{
  ::v-deep {
   color: $c-main-primary;
    font-weight: normal;
  }
}
*/
.text-enum .items__item__title,
.text-enum .content__title {
  ::v-deep b {
    color: $c-main-primary;
    font-weight: 300;
  }
}
.content__fact {
  @include fluid(
    margin-bottom,
    (
      xxs: 20px,
      m: 30px,
    )
  );

  border-bottom: 1px solid $c-main-primary;

  @include mq(s) {
    margin-top: auto;
  }
}

.content__fact__title {
  @include fluid(
    font-size,
    (
      xxs: 50px,
      xxl: 90px,
    )
  );

  color: $c-main-primary;
  font-family: $ff-alt;
  line-height: 1.2;
}

.items {
  counter-reset: counter;
  @include fluid(
    padding-left,
    (
      s: 0px,
      m: 40px,
    )
  );
}

.items__item {
  position: relative;
  display: flex;
  align-items: flex-start;
  //padding-left: col(1, 9);
  @include fluid(
    padding-left,
    (
      m: 70px,
      l: 90px,
    )
  );
  counter-increment: counter;

  &::before {
    // prettier-ignore
    background-color: $c-main-primary;
    border-radius: 15px;
    content: counter(counter);
    color: white;
    font-family: #{$ff-alt};
    font-weight: bold;
    @include fluid(
      font-size,
      (
        m: 30px,
        l: 36px,
      )
    );
    @include fluid(
      height,
      (
        m: 50px,
        l: 58px,
      )
    );
    left: 0;
    @include fluid(
      margin-top,
      (
        m: -5px,
        l: -10px,
      )
    );
    padding-top: 3px;
    position: absolute;
    text-align: center;
    top: 0;
    @include fluid(
      width,
      (
        m: 50px,
        l: 58px,
      )
    );
  }
  &.mb-m:last-child {
    margin-bottom: 0;
  }
}

.items__item__title {
  @include fluid(
    font-size,
    (
      xxs: 24px,
      xxl: 32px,
    )
  );
  display: block;
  line-height: 1.2;
}

.content__excerpt {
  //@extend %fw-light;
  // @include fluid-type(18, 24);
  font-family: $ff-default;
  line-height: 1.5;
}

.items__item .wysiwyg {
  // color: $c-gray-darker;
  //line-height: 1.8;
  ::v-deep {
    bottom: 0 !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}

.steps-item__icon {
  border-radius: $border-radius;
}
