
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        














.btn,
[class*='btn--'] {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 2rem;
  text-decoration: none;
  background-color: $c-main-primary;
  border: 0;
  border-radius: 30px;
  transition: background-color 0.15s, color 0.15s, border 0.15s;

  &:disabled {
    opacity: 0.7;
  }

  &.is-valid {
    background-color: $c-green;
  }

  &[class*='--big'] {
    padding: 1.5rem 2rem;
  }

  &[class*='--negative'] {
    background-color: transparent;
    border: 1px solid $c-white;
  }

  &[class*='--outline'] {
    background-color: $c-white;
    border: 1px solid $c-gray-lighter;
  }

  &[class*='--link'] {
    background-color: transparent;
    border-radius: initial;
  }

  &[class*='--reverse'] {
    flex-direction: row-reverse;
  }

  &:not(:disabled):hover {
    cursor: pointer;
    background-color: rgba($c-main-primary, 5);

    &[class*='--outline'] {
      background-color: $c-white;
      border: 1px solid rgba($c-main-primary, 5);
    }

    &[class*='--negative'] {
      border: 1px solid $c-white;
    }
  }

  @include mq(l) {
    padding: 1.2rem 2rem;
    &[class*='--small'] {
      padding: 1rem 2rem;
    }
    &[class*='--big'] {
      padding: 2.5rem 3.5rem;
    }
  }
}

.btn__label {
  display: inline-block;
  color: $c-white;
  font-size: 1.3rem;
  line-height: 1.5;

  [class*='btn--'][class*='--outline'] & {
    color: $c-main-primary;
  }

  @include mq(l) {
    font-size: 1.5rem;

    [class*='btn--'][class*='--small'] & {
      font-size: 1.5rem;
    }
  }
}

.btn__icon {
  width: 2.4rem;
  height: 2.4rem;
  padding-left: 1rem;
  fill: $c-white;
  transition: transform 0.15s;

  [class*='btn--'][class*='--no-icon'] & {
    display: none;
  }

  [class*='btn--'][class*='--reverse'] & {
    padding-right: 1rem;
    padding-left: 0;
    transform: rotate(180deg);
    transform-origin: 8px 12px;
  }

  [class*='btn--'][class*='--outline'] & {
    fill: $c-main-primary;
  }

  [class*='btn--'][class*='--negative'] & {
    fill: $c-white;
  }

  [class*='btn']:not(:disabled):hover & {
    transform: translateX(3px);
  }

  [class*='btn--'][class*='--reverse']:not(:disabled):hover & {
    //transform: translateX(-3px);
    transform: rotate(180deg) translateX(3px) !important;
  }

  [class*='btn--'][class*='--static']:not(:disabled):hover & {
    transform: none;
  }
}
