
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        








































































.macommune .input:not(.error-ean) {
  .form-error,
  .form-success {
    @media screen and (min-width: 767px) {
      left: 140px;
      width: unset;
    }
  }
}

.input {
  position: relative;
  color: $c-orange;
  border-bottom: 1px solid $c-gray-lighter;

  &::-ms-clear {
    display: none;
  }

  &::-webkit-search-cancel-button {
    -webkit-appearance: none; /* stylelint-disable-line property-no-vendor-prefix */
  }

  &--commune-alt {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    color: inherit;
    border-bottom: unset;
    margin-bottom: 2.3rem;
    @include mq(m) {
      flex-flow: row;
    }

    label {
      color: inherit;
      @include mq(m) {
        min-width: 14rem;
        flex: 0 0 auto;
      }
    }

    input {
      border: 1px solid $c-gray;
      border-radius: 0.5rem;
      height: 4.7rem;
      padding: 1rem;

      &:focus {
        border-color: $c-gray-darker;
      }
    }
  }
}

.input_container {
  display: flex;
}

.input__label {
  @media screen and (min-width: 720px) {
    height: 26.3px;
  }

  display: block;
}

.input__field {
  width: 100%;
  padding: 0.5rem 0 0.3rem;
  font-size: 1.8rem;
  background: transparent;
  border: 0;

  // pour microsoft edge, desactiver l'affichage du show password
  &::-ms-reveal {
    display: none;
  }

  &::placeholder {
    @extend %fw-light;

    color: $c-gray-light;
  }

  &:disabled {
    padding-left: 0.5em;
    color: darken($c-gray-light, 10%);
    background: lighten($c-gray-light, 10%);

    &:hover {
      cursor: not-allowed;
    }
  }
}

svg.hidden-password {
  position: absolute;
  right: 0.7rem;
  bottom: 0.4rem;
  fill: $c-gray-dark;
  cursor: pointer;
  transition: fill 0.3s ease-in-out;

  &:hover {
    fill: $c-gray-darker;
  }
}
