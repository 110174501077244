
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        



























































































































.my-commune-header {
  position: relative;
  z-index: 10;
  display: flex;
  align-items: center;
  height: $header-height-commune;
  margin-top: -$header-height-commune;
  padding-left: calc((100% - #{$content-width}) / 2);
  background-color: $c-white;
  border-bottom: 1px solid $c-gray-light;

  > * + * {
    margin-left: 2.5rem;

    &.offline {
      margin-left: auto;
      margin-right: 3rem;
    }
  }
}

.my-header__logo {
  padding-left: 2rem;
  margin-right: auto;
  display: flex;

  @include mq(m) {
    padding-left: 3rem;
  }
}

.header__nav__logo {
  position: relative;
  z-index: 2;
  display: block;
  margin-left: 2rem;

  svg {
    // prettier-ignore
    @include fluid(width, (xxxs: 60px, xl: 100px));

    height: auto;
  }
}

.my-commune-menu__slider {
  overflow-y: auto;
  padding: 9.2rem 4.6rem;
  pointer-events: auto;
  opacity: 0;
  transform: translateX(100%);
  position: fixed;
  top: $header-height-commune;
  right: 0;
  background-color: $c-white;
  height: 100vh;
  max-height: calc(100vh - 70px);
  width: 100%;
  max-width: 400px;
  border-left: 1px solid $c-gray-light;
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
  margin-left: unset;

  &.is-open {
    transform: translateX(0);
    opacity: 1;
    box-shadow: -400px 0 0 rgba(0, 0, 0, 0.3), -800px 0 0 rgba(0, 0, 0, 0.3),
      -1200px 0 0 rgba(0, 0, 0, 0.3), -1600px 0 0 rgba(0, 0, 0, 0.3),
      -2000px 0 0 rgba(0, 0, 0, 0.3), -2400px 0 0 rgba(0, 0, 0, 0.3),
      -2800px 0 0 rgba(0, 0, 0, 0.3), -3200px 0 0 rgba(0, 0, 0, 0.3),
      -3600px 0 0 rgba(0, 0, 0, 0.3), -4000px 0 0 rgba(0, 0, 0, 0.3),
      -4400px 0 0 rgba(0, 0, 0, 0.3), -4800px 0 0 rgba(0, 0, 0, 0.3),
      -5200px 0 0 rgba(0, 0, 0, 0.3), -5600px 0 0 rgba(0, 0, 0, 0.3),
      -6000px 0 0 rgba(0, 0, 0, 0.3), -6400px 0 0 rgba(0, 0, 0, 0.3);
  }
}
