.my-panel,
[class*='my-panel--'] {
  position: absolute;
  z-index: 1;
  top: calc(100% + 2rem);
  left: 0;
  padding: 2.4rem 3.2rem;
  font-size: 1.5rem;
  background-color: $c-white;
  border: 1px solid rgba($c-black, 0.1);
  box-shadow: 0 1rem 3rem rgba($c-black, 0.1);
  // DEV
  min-width: 24rem;

  a {
    display: block;
    margin-bottom: 1rem 0;
    text-decoration: none;
  }

  hr {
    height: 1px;
    margin: 2rem 0;
    border: 0;
    background: $c-gray-lighter;
  }

  .router-link-active {
    color: $c-orange;
  }
}

[class*='my-panel--'][class*='--right'] {
  right: 0;
  left: auto;
}

$w: 1.8rem;
$h: 1.2rem;

.my-panel__arrow {
  position: absolute;
  top: -$h;
  left: $w;
  overflow: hidden;
  width: $w;
  height: $h;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    display: block;
    width: $w;
    height: $w;
    background-color: $c-white;
    border: 1px solid rgba($c-black, 0.1);
    box-shadow: 0 1rem 3rem rgba($c-black, 0.1);
    transform: scaleX(0.75) rotate(45deg);
  }

  [class*='my-panel--'][class*='--right'] & {
    right: $w;
    left: auto;
  }
}

.my-panel__title {
  margin-bottom: 1rem;
  color: $c-gray-darker;
}
